import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Placeholder, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchStoresOTPs } from '../../slice/dashBoardSlice';

function OTPList() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [otpList, setOtpList] = useState([]);

    useEffect(() => {
        getOrders();
    }, []);

    const getOrders = () => {
        dispatch(fetchStoresOTPs()).then((response) => {
            setOtpList(response.payload);
            setDataLoaded(true);
        });
    }

    const orderDetails = (order) => {
        navigate('/ordersummary?orderId=' + order.id)
    }

    return (
        <>
            <Row className='g-0 justify-content-center orderHistory'>
                <Col xs={12} md={12} lg={6}>
                    <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>
                            <h5 className='mb-0 w-100 pe-4 fs-6'>Order History</h5>
                            <Button size='sm' className='bg-transparent text-dark border-0'>
                                <i class="fas fa-close" onClick={() => navigate('/home')}></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='g-0 p-3 pt-2 card-height d-block'>
                        {dataLoaded === true ?
                            <>
                                {otpList && otpList.map((itm, idx) => {
                                    if (itm.mobileNumber)
                                        return (
                                            <>
                                                <Col xs={12}>
                                                    <Card className='cardItem mb-2' >
                                                        <Card.Header className='p-2 border-bottom bg-light text-decoration-none text-dark' role='button'>
                                                            <Row className='g-0 fs-14'>
                                                                <Col xs={12} className='d-flex align-items-start align-items-md-center'>
                                                                    {/* <Image width='50px' height='50px' className='me-3 rounded' src={baseUrl + "/" + itm?.store?.storeLogo} /> */}
                                                                    <div className='w-100 align-self-center'>
                                                                        <h4 className="fs-14 fw-bold mb-1">{itm?.mobileNumber}</h4>
                                                                        <div className='d-flex flex-wrap'>
                                                                            <span className="fs-12 me-auto pe-3 text-blue d-flex align-items-center">
                                                                                <span className=''>{itm?.store?.address}</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Card.Header>
                                                        <Card.Body className='p-2 text-decoration-none' role='button'>
                                                            <Row className='g-0 fs-14'>
                                                                <Col xs={12} className=''>
                                                                    <ul className='list-group mb-2 fs-12'>
                                                                        OTP: -  {itm.otp}
                                                                    </ul>
                                                                    <ul className='list-group list-group-flush' style={{ borderTop: '1px dashed #dee2e6' }}>
                                                                        {/* <li className="list-group-item px-0">
                                                                        <div className='d-flex flex-wrap' >
                                                                            <span className="fs-12 me-auto pe-3 text-blue  ">
                                                                                {itm.orderDate}
                                                                            </span>
                                                                            <span className='fs-12 fw-bold ms-auto'><i class="fas fa-indian-rupee-sign fs-11 text-blue"></i>{itm.orderTotal}</span>
                                                                        </div>
                                                                    </li> */}
                                                                        <li className="list-group-item px-0 pb-0">
                                                                            <div className='d-flex flex-wrap align-items-center'>
                                                                                <span className={`fs-12 me-auto pe-3 ${itm.statusClr}`}>
                                                                                    <i class={`fas ${itm.statusIcon} me-1`}></i>{itm.status}
                                                                                </span>
                                                                                {/* <span className="fs-12 ms-auto text-blue" onClick={() => orderDetails(itm)}>
                                                                                More Details<i class="fas fa-caret-right ms-1 text-blue"></i>
                                                                            </span> */}
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>                                                       
                                                    </Card>
                                                </Col>
                                            </>
                                        )
                                }
                                )}

                            </> :
                            <Placeholder animation="glow" as='row' className='g-0'>
                                {otpList && otpList.map((det, idx) => {
                                    return (
                                        <>
                                            <Placeholder xs={12} className='rounded mb-2' style={{ height: '230px' }} />
                                        </>
                                    )
                                }
                                )}
                            </Placeholder >}
                    </Row>
                </Col>
            </Row>
        </>
    )
}


export default (OTPList);
