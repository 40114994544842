import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, Carousel, Col, FloatingLabel, Form, Image, Placeholder, ProgressBar, Row } from 'react-bootstrap';
import profileImg from '../../assets/images/user.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

function YourProfile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [data, setData] = useState(false);


    useEffect(() => {
        if (data) {
            setTimeout(() => {
                setDataLoaded(true);
            }, 1000);
        } else {
            setTimeout(() => {
                setDataLoaded(true);
            }, 1000);
        }
    }, []);

    const updateProfile = () => {
    }

    const handleChange = () => { }

    return (
        <>
            <Row className='g-0 justify-content-center brandInformation'>
                <span className='bg-img1' style={{ zIndex: '-1' }}></span>
                <Col xs={12} md={12} lg={6}>
                    <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>
                            <Button size='sm' onClick={() => navigate('/home')} className='bg-transparent text-dark border-0'>
                                <i class="fas fa-arrow-left"></i>
                            </Button>
                            <h5 className='mb-0 w-100 text-center fs-6 pe-4'>Your Profile</h5>
                        </Col>
                    </Row>
                    <Row className='g-0 p-3 pt-2 card-height d-block'>
                        {dataLoaded === true ?
                            <Col xs={12} md={12} className=''>
                                <Row className='g-0'>
                                    <Col xs={12} md={12} className='mb-4 mb-2'>
                                        <div className='profile_box'>
                                            <div className='profile_img'>
                                                <img src={profileImg} alt='Profile' />
                                            </div>
                                            {/* <input type='file' id='uploadImage' onChange={handleChangeImage} /> */}
                                            <Button htmlFor='uploadImage' as='label' className='fs-12 position-absolute translate-middle p-0 border-light rounded-circle profile_edit_btn'>
                                                <i class="fas fa-pen"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <FloatingLabel className="mb-2" label="Name" controlId="floatingTextarea" >
                                            <Form.Control name='name' className='shadow-box' type="text" placeholder="Name" onChange={handleChange} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs={12}>
                                        <FloatingLabel className="mb-2" label="Mobile" controlId="floatingTextarea" >
                                            <Form.Control name='mobileNumber' className='shadow-box' type="text" placeholder="Mobile" onChange={handleChange} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs={12}>
                                        <FloatingLabel className="mb-2" label="Email" controlId="floatingTextarea" >
                                            <Form.Control name='email' className='shadow-box' type="text" placeholder="Email" onChange={handleChange} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs={12}>
                                        <FloatingLabel className="mb-2" label="DOB" controlId="floatingTextarea" >
                                            <Form.Control name='dob' className='shadow-box' type="text" placeholder="DOB" onChange={handleChange} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs={12}>
                                        <FloatingLabel controlId="floatingSelect" label="Gender" className='border-0'>
                                            <Form.Select aria-label="Floating label select example" className='shadow-box rounded border-0'>
                                                <option></option>
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            </Col> :
                            <Placeholder animation="glow" as='row' className='g-0'>
                                <Placeholder xs={12} className='rounded-circle mx-auto d-block mb-4' style={{ width: '70px', height: '70px' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '57px' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '57px' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '57px' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '57px' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '57px' }} />
                            </Placeholder>}
                    </Row>
                    <Row className='g-0 position-relative'>
                        <Col xs={12} lg={6} className='px-3 btn-position'>
                            {dataLoaded === true ?
                                <Button onClick={updateProfile} className='w-100 py-3 foot-btn bg-light-blue text-truncate'>
                                    Update Profile
                                </Button>
                                :
                                <Placeholder animation="glow" as='row' className='g-0'>
                                    <Placeholder.Button xs={12} className='rounded' style={{ height: '56px' }} />
                                </Placeholder>}
                        </Col>
                    </Row>
                </Col >
            </Row >

        </>
    )
}


export default (YourProfile);
