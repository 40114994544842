import React from 'react';
import { Col, Row, Button, Modal, } from 'react-bootstrap';

function SuccessModal(props) {

    return (
        <>
            <Modal show={props.showSuccessModal} centered size='md' >
                <Modal.Body>
                    <Row className='g-0 fs-14'>
                        <Col xs={12} className=''>
                            <div class="check-container">
                                <div class="check-background">
                                    <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div class="check-shadow"></div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <h5 className='text-center fs-14 fw-bold'>
                                Thank you for your review!
                            </h5>
                            <h5 className='text-center fs-12 mb-3 text-blue'>
                                your review will be updated shortly.
                            </h5>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}



export default (SuccessModal);
