import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, ListGroup, Offcanvas, Placeholder, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getStoreList } from '../../actions/DashBoard/getDashBoardData';
import { useNavigate } from 'react-router-dom';
import { fetchLocationBySearchText, fetchPlaceDetailByID, fetchStoreByKeyCode, loginWithGuest } from '../../slice/dashBoardSlice';
import Cookies from 'js-cookie';
import noImage from '../../assets/images/no-image.png';

function ChooseBrands() {

    const baseUrl = localStorage.getItem('baseUrl');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [brandItem, setBrandItem] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [locations, setLocations] = useState([]);
    const [filter, setFilter] = useState("");
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        if (Cookies.get('location')) {
            getStores(Cookies.get('location'));
        } else {
            setDataLoaded(true);
        }
    }, []);

    const getStores = (location) => {
        let prepareGeometry = location ? JSON.parse(location) : null;
        if (Cookies.get('authenticated') === "true" || Cookies.get('authenticated') === true) {
            if (Cookies.get('store')) {
                navigate('/home');
                setDataLoaded(true);
            } else {
                try {
                    dispatch(getStoreList(prepareGeometry)).then((list) => {
                        if (list && list.payload) {
                            const stores = JSON.parse(JSON.stringify(list.payload));
                            if (stores && stores.length === 1) {
                                chooseStore(stores[0])
                            }
                            setBrandItem(stores);
                            setTimeout(() => {
                                setDataLoaded(true);
                            }, 500);
                        } else {
                            setBrandItem([]);
                            setDataLoaded(true);
                        }
                    })
                } catch (err) {
                    console.log(err);
                }
            }
        } else {
            dispatch(loginWithGuest(prepareGeometry)).then((async (response) => {
                if (response.payload && response.payload.token) {

                    Cookies.set('E_COMM_TOKEN', response.payload.token, { expires: 30 });
                    Cookies.set('authenticated', true, { expires: 30 });
                    Cookies.set('guest', true, { expires: 30 });

                    const baseUrl = response.payload.resourceBaseUrl;
                    localStorage.setItem('baseUrl', baseUrl);
                    localStorage.removeItem('cart');

                    if (prepareGeometry) {
                        try {
                            await dispatch(getStoreList(prepareGeometry)).then((list) => {
                                const stores = JSON.parse(JSON.stringify(list.payload));
                                if (stores && stores.length === 1) {
                                    Cookies.set('store', JSON.stringify(stores[0]));
                                    chooseStore(stores[0])
                                }
                                setBrandItem(stores);
                                setTimeout(() => {
                                    setDataLoaded(true);
                                }, 500);
                            })
                        } catch (e) {
                            setDataLoaded(true);
                            console.log("Something went wrong, please try again");
                        }
                    } else {
                        setTimeout(() => {
                            setDataLoaded(true);
                        }, 500);
                        alert("Please enable your current location");
                    }
                } else {
                    setTimeout(() => {
                        setDataLoaded(true);
                    }, 500);
                }

            }));
        }
    }

    const handleInputChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    }

    const onChangeLocation = (e) => {
        dispatch(fetchLocationBySearchText(e.target.value.toLowerCase())).then(((response) => {
            if (response.payload.predictions) {
                setLocations(response.payload.predictions);
            } else {
                setLocations([]);
            }
        }))
    }

    const chooseLocation = (placeId) => {
        dispatch(fetchPlaceDetailByID(placeId)).then(((response) => {
            if (response.payload.result.geometry.location) {

                let geometry = response.payload.result.geometry.location;

                let prepareGeometry = {
                    locationLat: geometry.lat,
                    locationLong: geometry.lng
                }

                dispatch(loginWithGuest(prepareGeometry)).then((async (response) => {
                    if (response.payload && response.payload.token) {

                        Cookies.set('E_COMM_TOKEN', response.payload.token, { expires: 30 });
                        Cookies.set('authenticated', true, { expires: 30 });
                        Cookies.set('guest', true, { expires: 30 });
                        localStorage.removeItem('cart');
                        Cookies.set('location', JSON.stringify(prepareGeometry), { expires: 30 });
                        const baseUrl = response.payload.resourceBaseUrl;
                        localStorage.setItem('baseUrl', baseUrl);

                        const searchParams = new URLSearchParams(window.location.search);

                        if (searchParams.get('code')) {
                            await dispatch(fetchStoreByKeyCode(searchParams.get('code'))).then((response) => {
                                chooseStore(response.payload)
                            })
                        } else {
                            getStores(JSON.stringify(prepareGeometry));
                        }
                        setShowMap(false);
                    }
                }))
            }
        }))
    }

    const chooseStore = (store) => {
        Cookies.set('store', JSON.stringify(store), { expires: 30 });
        navigate('/home');
    }

    const chooseCurrentLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {

                let prepareGeometry = {
                    locationLat: position.coords.latitude,
                    locationLong: position.coords.longitude
                }

                dispatch(loginWithGuest(prepareGeometry)).then((async (response) => {
                    if (response.payload && response.payload.token) {

                        Cookies.set('E_COMM_TOKEN', response.payload.token, { expires: 30 });
                        Cookies.set('authenticated', true, { expires: 30 });
                        Cookies.set('guest', true, { expires: 30 });
                        localStorage.removeItem('cart');
                        Cookies.set('location', JSON.stringify(prepareGeometry), { expires: 30 });
                        const baseUrl = response.payload.resourceBaseUrl;
                        localStorage.setItem('baseUrl', baseUrl);

                        const searchParams = new URLSearchParams(window.location.search);

                        if (searchParams.get('code')) {
                            await dispatch(fetchStoreByKeyCode(searchParams.get('code'))).then((response) => {
                                chooseStore(response.payload)
                            })
                        } else {
                            getStores(JSON.stringify(prepareGeometry));
                        }
                        setShowMap(false);
                    }
                }))
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    }

    return (
        <>
            <Row className='g-0 justify-content-center payment'>
                <Col xs={12} md={12} lg={6}>
                    <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>
                            <Button size='sm' href='/welcomescreen' className='bg-transparent text-dark border-0'>
                                <i class="fas fa-arrow-left"></i>
                            </Button>
                            <h5 className='mb-0 w-100 text-center pe-4 fs-6'>Top Brands</h5>
                        </Col>
                    </Row>
                    <Row className='g-0 p-3 pt-2 card-height d-block'>
                        {dataLoaded === true ?
                            <>
                                <Col xs={12} className='mb-3'>
                                    <h5 className='fs-14 fw-bold'>Choose a brand</h5>
                                    <div className="searchBox ">
                                        <Button size='sm' className='search-icon'>
                                            <i class="fas fa-search"></i>
                                        </Button>
                                        <Form.Control className='form-input pe-3 shadow-sm' placeholder="Search brands" type='search' aria-label="search" aria-describedby="basic-addon1" value={filter} id="myInput" onChange={handleInputChange} />
                                    </div>
                                </Col>
                                <Col xs={12} md={12} className=''>
                                    <Row className='g-2'>
                                        {brandItem && brandItem.length > 0 ?
                                            brandItem.map((item, idx) => {
                                                console.log(item);
                                                const { name, type } = item;
                                                if (!name) return null;
                                                const txtValue = name.toLowerCase();
                                                const displayStyle = txtValue.indexOf(filter) > -1 ? '' : 'none';
                                                return (
                                                    <>
                                                        <Col xs={6} key={idx} style={{ display: displayStyle }} onClick={() => chooseStore(item)}>
                                                            <Button variant='primary' className='light-btn w-100 p-3'>
                                                                <Image width={60} className='mx-auto mb-3 d-block' src={baseUrl + "/" + item.storeLogo || noImage} />
                                                                <h3 className='fs-14 mb-0'>{item.name}</h3>
                                                            </Button>
                                                        </Col>
                                                    </>
                                                );
                                            }) :
                                            <Col xs={12}>
                                                <h5 className='fs-14'> Sorry, store not available to your nearest location.</h5>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </> :
                            <Placeholder animation="glow" >
                                <Placeholder xs={12} className='mb-2' style={{ width: '45%' }} />
                                <Placeholder xs={12} className='rounded mb-3' style={{ height: '40px' }} />
                                <Row className='g-2'>
                                    <Col xs={6}>
                                        <Placeholder className='rounded' style={{ width: '100%', height: '125px' }} />
                                    </Col>
                                    <Col xs={6}>
                                        <Placeholder className='rounded' style={{ width: '100%', height: '125px' }} />
                                    </Col>
                                    <Col xs={6}>
                                        <Placeholder className='rounded' style={{ width: '100%', height: '125px' }} />
                                    </Col>
                                    <Col xs={6}>
                                        <Placeholder className='rounded' style={{ width: '100%', height: '125px' }} />
                                    </Col>
                                    <Col xs={6}>
                                        <Placeholder className='rounded' style={{ width: '100%', height: '125px' }} />
                                    </Col>
                                    <Col xs={6}>
                                        <Placeholder className='rounded' style={{ width: '100%', height: '125px' }} />
                                    </Col>
                                </Row>
                            </Placeholder>}

                    </Row>
                </Col>
            </Row>

            <Offcanvas className='home-offcan offcanvas_top' placement='top' show={showMap}
                style={{ height: 'fit-content' }}>
                <Offcanvas.Body>
                    <Row className='g-0'>
                        <Col xs={12} className='d-flex flex-wrap align-items-center '>
                            <Offcanvas.Title className='fs-14 fw-bold me-auto'>Select your Location</Offcanvas.Title>
                            <Button className='bg-transparent border-0 shadow-none text-dark pe-none opacity-0'>
                                <i class="fas fa-close"></i>
                            </Button>
                        </Col>
                        <Col xs={12} className='mb-2'>
                            <div className="searchBox ">
                                <Button size='sm' className='search-icon'>
                                    <i class="fas fa-search"></i>
                                </Button>
                                <Form.Control className='form-input pe-3 shadow-sm' placeholder="search delivery location" aria-label="search" aria-describedby="basic-addon1" onChange={onChangeLocation} />
                            </div>
                        </Col>
                        <Col xs={12} className='mb-3'>
                            <Button variant='primary' className='light-btn text-green bg-transparent shadow-none' onClick={() => chooseCurrentLocation()}>
                                <i class="fas fa-location me-1 fs-6"></i>Use Current Location
                            </Button>
                            <hr className='m-0 mt-2' />
                        </Col>
                        <Col xs={12}>
                            <Card className={`cardItem ${locations.length === 0 ? "d-none" : "d-block"}`}>
                                <Card.Body className='px-0 py-2'>
                                    <ListGroup className={locations.length === 0 ? "" : "map_list_group"}>
                                        {locations ?
                                            locations.map((loc, index) => {
                                                return (
                                                    <ListGroup.Item action key={index} className='' onClick={() => chooseLocation(loc.place_id)}>
                                                        <Row className='g-0 fs-14'>
                                                            <Col xs={12} sm={12} className='d-flex align-items-center'>
                                                                <span className='me-3 '>
                                                                    <i class="fas fa-location-dot fs-6 text-blue"></i>
                                                                </span>
                                                                <Row className='g-0 w-100'>
                                                                    <Col xs={12}>
                                                                        {loc.description}
                                                                    </Col>
                                                                    <Col xs={12} className='fs-12'>
                                                                        {loc.description}
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )
                                            })
                                            : null
                                        }

                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Offcanvas.Body >
            </Offcanvas >
            {/* </div>} */}

        </>
    )
}



export default (ChooseBrands);
