import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Image, Placeholder, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { changeOrderStatus, getOrderDetailsById } from '../../slice/dashBoardSlice';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import noImage from '../../assets/images/no-image.png';
import shippingImg from '../../assets/images/shipping-invoice.jpg';
import PreviewImageModal from './PreviewImageModal';
import CancelOrderModal from '../common/CancelOrderModal';
import ConfirmationModal from '../common/ConfirmationModal';

function OrderSummary() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const baseUrl = localStorage.getItem('baseUrl');

    const [dataLoaded, setDataLoaded] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});

    const searchParams = new URLSearchParams(window.location.search);

    const [orderTrack, setOrderTrack] = useState([
        { name: 'Order Placed', date: '2024-09-12T00:00:00.000Z', isActive: true },
        { name: 'Shipped', date: 'Expected by 14 September, 2024', isActive: false },
        { name: 'Delivered', date: 'Expected by 22 September, 2024', isActive: false },
    ]);
    const [previewImage, setPreviewImage] = useState(false);
    const [showMessage, setShowMessage] = useState({ open: false, message: '', messageText: '', msgDescription: '', buttonText: '' });
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    useEffect(() => {
        getOrders();
    }, ['']);

    const getOrders = async () => {
        await dispatch(getOrderDetailsById(searchParams.get('orderId'))).then((response) => {
            if (response.payload && !response.payload.error) {
                setOrderDetails(response.payload);
                setDataLoaded(true);
            }
        });
    }

    const iconClassName = (status) => {
        if (status === 'OPEN' || status === 'COMPLETED') {
            return 'circle-check';
        } else if (status === 'FAILED') {
            return 'circle-exclamation';
        } else if (status === 'CANCELLED') {
            return 'times-circle';
        } else {
            return '';
        }
    }

    const colorClassName = (status) => {
        if (status === 'OPEN' || status === 'COMPLETED') {
            return 'text-green';
        } else {
            return 'text-red';
        }
    }

    const handlePreview = (e) => {
        setPreviewImage(e.previewImage);
    };


    const formatOrderDate = (dateStr) => {
        if (!dateStr) return '';

        const date = new Date(dateStr);
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour12: true
        };
        return date.toLocaleString('en-US', options);
    };

    const formatOrderTrack = (dateStr) => {
        if (!dateStr) return '';

        const date = new Date(dateStr);
        const timeOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        const dateOptions = {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        };

        const time = date.toLocaleTimeString('en-US', timeOptions);
        const formattedDate = date.toLocaleDateString('en-US', dateOptions);

        return `${time}, ${formattedDate}`;
    };

    const cancelOrder = (message) => {
        // if (message = true) {
        //     setShowMessage({ open: true, message: "SUCCESS", messageText: "Order Cancelled", msgDescription: "Your order cancelled Successfully.", buttonText: "Go To Home", });
        // } else {
        //     setShowMessage({ open: true, message: "FAILED", messageText: "Order Failed", msgDescription: "Please try again", buttonText: "Try Again" });
        // }
        setShowConfirmationDialog(true)
    };

    const onCancelConfirm = async (flag) => {
        if (flag === 'YES') {
            await dispatch(changeOrderStatus({ orderId: orderDetails.id, status: "cancel" })).then((response) => {
                if ((response.payload && !response.payload.error)) {
                    setShowConfirmationDialog(false);
                    setShowMessage({ open: true, message: "SUCCESS", messageText: "Order Cancelled", msgDescription: "Your order cancelled Successfully.", buttonText: "Go To Order History", });
                } else {
                    setShowMessage({ open: true, message: "FAILED", messageText: "Order Failed", msgDescription: "Please try again", buttonText: "Try Again" });
                }
            })
        } else {
            setShowConfirmationDialog(false);
        }

    }

    const onCloseButton = (message) => {
        if (message = true) {
            setShowMessage({ open: false, message: "", messageText: '', msgDescription: '', buttonText: '' });
            localStorage.removeItem('ordersummary');
            navigate('/orderHistory');
        } else {
            setShowMessage({ open: false, message: "", messageText: '', msgDescription: '', buttonText: '' });
        }

    };

    return (
        <>
            <Row className='g-0 justify-content-center payment'>
                <Col xs={12} md={12} lg={6}>
                    <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>
                            <Button size='sm' onClick={() => navigate('/orderhistory')} className='bg-transparent text-dark border-0'>
                                <i class="fas fa-arrow-left"></i>
                            </Button>
                            <h5 className='mb-0 w-100 text-center fs-6'>Order Summary</h5>
                            {/* <Button size='sm' className='bg-transparent text-dark border-0' onClick={() => toggleIcon()}>
                                <i class={`${favoriteIcon === true ? 'fas' : 'far'} fa-heart`}></i>
                            </Button> */}
                            <Button size='sm' className='bg-transparent text-dark border-0 ms-auto'>
                                <i class='fas fa-download'></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='g-0 p-3 pt-2 card-height d-block'>
                        {orderDetails?.status === 'OPEN' ?
                            dataLoaded === true ?
                                <Col xs={12} md={12} >
                                    <Button variant='primary' className={`light-btn w-100 p-3 mb-3 fs-14`} style={{ lineHeight: 'normal' }} onClick={cancelOrder}>
                                        Cancel Order
                                    </Button>
                                </Col> :
                                <Placeholder animation="glow" as='row' className='g-0'>
                                    <Placeholder xs={12} className='rounded mb-3' style={{ height: '50px' }} />
                                </Placeholder> : null}

                        {dataLoaded === true ?
                            <>
                                <Col xs={12} md={12} className=''>
                                    <div className='d-flex flex-wrap align-items-center'>
                                        <h5 className='fs-14 fw-bold'>Your Order</h5>
                                        <span className={`fs-12 ms-auto ps-3 ${colorClassName(orderDetails?.status)}`}>
                                            <i class={`fas fa-${iconClassName(orderDetails?.status)} me-1`}></i>{orderDetails?.status === 'OPEN' ? 'PlACED' : orderDetails?.status}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <Card className='cardItem mb-2' >
                                        <Card.Body className='p-2'>
                                            <Row className='g-0 fs-14'>
                                                <Col xs={12} className=''>
                                                    <ul className='list-group list-group-flush mb-0 fs-14 w-100'>
                                                        {orderDetails && orderDetails.items.map((item, idx) => {
                                                            return (
                                                                <>
                                                                    <li key={idx} className="list-group-item px-0">
                                                                        <Row className='g-0 fs-14'>
                                                                            <Col xs={12} className='d-flex align-items-start'>
                                                                                <Image width='14px' className='me-2' src={item.icon ? baseUrl + "/" + item.icon : noImage} />
                                                                                <div className='w-100 align-self-center'>
                                                                                    <h4 className="fs-12">{item.prodAlias}</h4>
                                                                                    <div className='d-flex flex-wrap'>
                                                                                        <span className="fs-12 me-auto pe-3 d-flex align-items-center">
                                                                                            <span className=''>
                                                                                                *{item.qty}
                                                                                            </span>
                                                                                            <div className='divider'></div>
                                                                                            <span className=''>
                                                                                                <i class="fas fa-indian-rupee-sign fs-10 text-blue"></i>{item.price}
                                                                                            </span>
                                                                                        </span>
                                                                                        <span className='fs-12 ms-auto'><i class="fas fa-indian-rupee-sign fs-10 text-blue"></i>{parseInt(item.price) * parseInt(item.qty)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </li>
                                                                </>
                                                            )
                                                        }
                                                        )}
                                                        <li className="list-group-item px-0 pb-0">
                                                            <Row className='g-0 d-flex align-items-center mt-2'>
                                                                <Col xs={12}>
                                                                    <Row className='g-0 fs-14 mb-1'>
                                                                        <Col md={6} xs={6} className="d-flex justify-content-start align-items-center text-decoration-underline text-blue">
                                                                            Item Total
                                                                        </Col>
                                                                        <Col md={6} xs={6} className="d-flex justify-content-end align-items-center">
                                                                            <i class="fas fa-indian-rupee-sign text-blue fs-11"></i>{orderDetails.orderTotal}
                                                                        </Col>
                                                                    </Row>
                                                                    {/* <Row className='g-0 fs-12 mb-1'>
                                                                        <Col md={6} xs={6} className="d-flex justify-content-start align-items-center text-decoration-underline text-blue">
                                                                            Total Discount
                                                                        </Col>
                                                                        <Col md={6} xs={6} className="d-flex justify-content-end align-items-center text-green">
                                                                            <i class="fas fa-indian-rupee-sign text-blue fs-10"></i>0
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='g-0 fs-12 mb-1'>
                                                                        <Col md={6} xs={6} className="d-flex justify-content-start align-items-center text-decoration-underline text-blue">
                                                                            Taxes
                                                                        </Col>
                                                                        <Col md={6} xs={6} className="d-flex justify-content-end align-items-center">
                                                                            <i class="fas fa-indian-rupee-sign text-blue fs-10"></i>0
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='g-0 fs-12 mb-1'>
                                                                        <Col md={6} xs={6} className="d-flex justify-content-start align-items-center text-decoration-underline text-blue">
                                                                            Delivery Charges
                                                                        </Col>
                                                                        <Col md={6} xs={6} className="d-flex justify-content-end align-items-center text-blue">
                                                                            Free
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='g-0 fs-12 mb-2'>
                                                                        <Col md={6} xs={6} className="d-flex justify-content-start align-items-center text-decoration-underline text-blue">
                                                                            Packaging Charges
                                                                        </Col>
                                                                        <Col md={6} xs={6} className="d-flex justify-content-end align-items-center">
                                                                            <i class="fas fa-indian-rupee-sign text-blue fs-10"></i>0
                                                                        </Col>
                                                                    </Row> */}
                                                                    <Row className='g-0 fs-14 mb-2'>
                                                                        <hr className='mb-2' style={{ borderTop: '1px dashed' }} />
                                                                        <Col md={6} xs={6} className="d-flex justify-content-start  align-items-center ">
                                                                            <b>To Pay</b>
                                                                        </Col>
                                                                        <Col md={6} xs={6} className="d-flex justify-content-end  align-items-center fw-bold fs-14">
                                                                            <i class="fas fa-indian-rupee-sign text-blue fs-11" ></i>{orderDetails.orderTotal}
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                {/* <Col md={12} className='mt-1'>
                                                                    <Alert variant='success' className='fs-12 py-1 rounded-0 mb-2'>
                                                                        You have saved &nbsp;<i class="fas fa-indian-rupee-sign text-blue" ></i>0 on the bill
                                                                    </Alert>
                                                                </Col> */}
                                                                <Col xs={12} md={12}>
                                                                    <Row className='g-0 fs-12 mb-2'>
                                                                        <Col xs={12} className='d-flex align-items-start'>
                                                                            <span className='me-2'><i class="fa-solid fa-list-check text-green"></i></span>
                                                                            <div className='w-100 align-self-start'>
                                                                                <p className='mb-0'>Review your Order and address details to avoid cancellations</p>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </> :
                            <Placeholder animation="glow" as='row' className='g-0'>
                                <span className='d-flex'>
                                    <Placeholder xs={12} className='mb-2 me-auto' style={{ width: '25%' }} />
                                    <Placeholder xs={12} className='mb-2' style={{ width: '25%' }} />
                                </span>
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '440px' }} />
                            </Placeholder >}
                        {dataLoaded === true ?
                            <>
                                <Col xs={12} md={12} className='mt-3'>
                                    <h5 className='fs-14 fw-bold'>Order Details</h5>
                                </Col>
                                <Col xs={12}>
                                    <Card className='cardItem mb-2' >
                                        <Card.Body className='p-2'>
                                            <Row className='g-0'>
                                                <Col xs={12} className='my-2'>
                                                    <div className='d-flex flex-wrap'>
                                                        <span className="me-auto pe-3">
                                                            <h4 className="fs-12 mb-1 text-blue">Order Type</h4>
                                                            <p className="fs-12 mb-0">{orderDetails.deliveryMethod}</p>
                                                        </span>
                                                        <span className='ms-auto text-end'>
                                                            <h4 className="fs-12 mb-1 text-blue">Order Stage</h4>
                                                            <p className="fs-12 mb-0">{orderDetails.orderStage}</p>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xs={12} className='my-2'>
                                                    <div className='d-flex flex-wrap'>
                                                        <span className="me-auto pe-3">
                                                            <h4 className="fs-12 mb-1 text-blue">Order Number</h4>
                                                            <p className="fs-12 mb-0">{orderDetails.invoiceNo}</p>
                                                        </span>
                                                        <span className='ms-auto text-end'>
                                                            <h4 className="fs-12 mb-1 text-blue">Payment</h4>
                                                            <p className={`fs-12 mb-0  ${orderDetails.paymentStatus === 'PAID' ? 'text-green' : 'text-red'}`}>{orderDetails.paymentStatus}</p>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xs={12} className='my-2'>
                                                    <div className='d-flex flex-wrap'>
                                                        <span className="me-auto pe-3">
                                                            <h4 className="fs-12 mb-1 text-blue">Mobile Number</h4>
                                                            <p className="fs-12 mb-0">{orderDetails && orderDetails.address && orderDetails.address.mobileNumber ? orderDetails.address.mobileNumber : Cookies.get('mobileNumber') ? Cookies.get('mobileNumber') : ''}</p>
                                                        </span>
                                                        <span className='ms-auto text-end'>
                                                            <h4 className="fs-12 mb-1 text-blue">Date / Time</h4>
                                                            <p className="fs-12 mb-0">{formatOrderDate(orderDetails.orderDate)}</p>
                                                        </span>
                                                    </div>
                                                </Col>
                                                {orderDetails.address && orderDetails.address.id ?
                                                    <Col xs={12} className='my-2'>
                                                        <h4 className="fs-12 mb-1 text-blue">Deliver to</h4>
                                                        <p className="fs-12 mb-0">{orderDetails.address.line1 + " " + orderDetails.address.line2}</p>
                                                    </Col> :
                                                    null}
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </> :
                            <Placeholder animation="glow" as='row' className='g-0'>
                                <Placeholder xs={12} className='mb-2 mt-3' style={{ width: '25%' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '175px' }} />
                            </Placeholder >}
                        {dataLoaded === true ?
                            <>
                                <Col xs={12} md={12} className='mt-3'>
                                    <h5 className='fs-14 fw-bold'>Order Track</h5>
                                </Col>
                                <Col xs={12}>
                                    <Card className='cardItem mb-2' >
                                        <Card.Body className='p-2'>
                                            <Row className='g-0 fs-14'>
                                                <Col xs={12} sm={12} className=''>
                                                    <div className="tracking-timeline-with-icons">
                                                        <ul className="tracking-timeline-item">
                                                            {orderTrack.map((item, idx) => {
                                                                return (
                                                                    <li key={idx} className={item.isActive ? 'active' : ''}>
                                                                        <span className="tracking-timeline-icon"></span>
                                                                        <a href='#'>
                                                                            <span className="">
                                                                                <h4 className="fs-12 mb-1">{item.name}</h4>
                                                                                <p className="fs-10 mb-0">{formatOrderTrack(item.date)}</p>
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </> :
                            <Placeholder animation="glow" as='row' className='g-0'>
                                <Placeholder xs={12} className='mb-2 mt-3' style={{ width: '25%' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '136px' }} />
                            </Placeholder >}

                        {dataLoaded === true && orderDetails.shipmentReceipt ?
                            <>
                                <Col xs={12} md={12} className='mt-3'>
                                    <h5 className='fs-14 fw-bold'>Shipment Detail</h5>
                                </Col>
                                <Col xs={12}>
                                    <Card className='cardItem mb-2' >
                                        <Card.Body className='p-2'>
                                            <Row className='g-0 fs-14'>
                                                <Col xs={12} sm={12} className=''>
                                                    <Image width={100} height={100} role='button' className='rounded d-block mx-auto object-fit-cover' src={baseUrl + orderDetails.shipmentReceipt} onClick={() => handlePreview({ previewImage: true })} />
                                                    {/* {previewImage ?
                                                <div
                                                    style={{ padding: '20px', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
                                                    onClick={() => handlePreview({ previewLink: false })}
                                                >
                                                    <div style={{ padding: '20px',background:'#ffff', borderRadius:'10px' }}>
                                                        <Button className="btn-close ms-auto d-block bg-transparent mb-3 text-white"></Button>
                                                        <div className="" >
                                                            <Image width={300} height={300} className='rounded d-block mx-auto object-fit-cover' src={shippingImg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                : null} */}
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </> :
                            <Placeholder animation="glow" as='row' className='g-0'>
                                <Placeholder xs={12} className='mb-2 mt-3' style={{ width: '25%' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '116px' }} />
                            </Placeholder >}
                    </Row>
                </Col >
            </Row >

            <ConfirmationModal show={showConfirmationDialog} messageText="Are you sure you want to cancel the order?" hideModal={() => setShowConfirmationDialog(false)} handleClick={onCancelConfirm} />
            <PreviewImageModal show={previewImage} srcImage={baseUrl + orderDetails.shipmentReceipt} onCloseButton={() => handlePreview({ previewLink: false })} />

            <CancelOrderModal show={showMessage} onCloseButton={onCloseButton} />
        </>
    )
}


export default (OrderSummary);
