import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Nav, Placeholder, Row } from 'react-bootstrap';
import noProductImage from '../../assets/images/no-image.png';
import { json, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { useDispatch } from 'react-redux';
import { getProductDetails } from '../../slice/dashBoardSlice';


var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 280,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }
    ]
};

const noOfImages = 5;

function ProductDetails() {
    const baseUrl = localStorage.getItem("baseUrl")
    const orderCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {};
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [prodDataObject, setProdDataObject] = useState({});

    const [dataLoaded, setDataLoaded] = useState(false);
    const [videoUrl, setVideoUrl] = useState('')
    const [data, setData] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewLink, setPreviewLink] = useState('');
    const [fileList, setFileList] = useState([]);

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [addQty, setAddQty] = useState(false);
    const [qty, setQty] = useState(1);

    useEffect(() => {
        if (data) {
            setTimeout(() => {
                setDataLoaded(true);
            }, 2000);
        } else {
            setTimeout(() => {
                setDataLoaded(true);
            }, 2000);
        }
        getProductDetailsWithId();
    }, []);

    const getProductDetailsWithId = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        if (localStorage.getItem('prdId') || searchParams.get('id')) {
            let prdId = localStorage.getItem('prdId') ? localStorage.getItem('prdId') : searchParams.get('id');
            try {
                await dispatch(getProductDetails(prdId)).then((response) => {
                    if (response.payload) {
                        const fileListEmpty = []
                        for (let i = 0; i < noOfImages; i++) {
                            if (response.payload[`image${i + 1}`] === 'Y') {
                                fileListEmpty.push({ fileSrc: response.payload?.icon ? (baseUrl + response.payload.icon?.replace("_1", `_${i + 1}`)) : null, fileName: (response.payload.id + "_" + i + 1) });
                            }
                        }
                        if (response.payload.videoUrl && response.payload.videoUrl !== 'N') {
                            setVideoUrl(response.payload.videoUrl === 'Y' ? baseUrl + String(response?.payload.icon || "").replace("_1.jpg", "_v.mp4") : response.payload.videoUrl);
                        }
                        if (fileListEmpty.length > 0) {
                            setPreviewImage(fileListEmpty[0].fileSrc)
                        }
                        if (orderCart && orderCart.items) {
                            const productFound = orderCart.items.find(e => e.productId === response.payload.id);
                            if (productFound) {
                                console.log(productFound.qty)
                                setAddQty(true);
                                setQty(productFound.qty)
                            }
                        }
                        setFileList(fileListEmpty)
                        setProdDataObject(response.payload);
                    }
                })
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleBack = () => {
        navigate(-1);
    }

    const handlePreview = (url, idx, type) => {
        setActiveItemIndex(idx);
        if (type === 'video') {
            setPreviewLink(url);
            setPreviewImage('');
        } else {
            setPreviewImage(url);
            setPreviewLink('');
        }
    };

    const handleShareClick = async () => {
        const textToShare = encodeURIComponent(
            'https://difoo.insonix.com/productdetails?id=' + localStorage.getItem('prdId')
        );

        const imgData = copyToClipboard('https://cdn.pixabay.com/photo/2017/09/03/00/44/png-2709031_1280.png');
        // window.location.href = whatsappUrl;
    };

    async function copyToClipboard(src) {
        const data = await fetch(src);
        const blob = await data.blob();
        try {
            await navigator.clipboard.write([new ClipboardItem({
                [blob.type]: blob,
            })
            ])
            console.log("Success");
        } catch (e) {
            console.log(e);
        }
    }


    const inc = () => {
        const productFoundIndx = orderCart.items.findIndex(e => e.productId === prodDataObject.id);
        if (productFoundIndx > -1 && orderCart && orderCart.items) {
            let items = JSON.parse(JSON.stringify(orderCart.items));
            let updateItem = items.find(itm => itm.productId === prodDataObject.id);
            updateItem.qty = updateItem.qty + 1;
            items[productFoundIndx] = updateItem;
            localStorage.setItem('cart', JSON.stringify({ ...orderCart, items }))
            setQty(qty + 1);
        }

    };

    const dec = () => {
        const productFoundIndx = orderCart.items.findIndex(e => e.productId === prodDataObject.id);
        if (productFoundIndx > -1 && orderCart && orderCart.items) {
            let items = JSON.parse(JSON.stringify(orderCart.items));
            let updateItem = items.find(itm => itm.productId === prodDataObject.id);
            if (qty > 1) {
                updateItem.qty = updateItem.qty - 1;
                items[productFoundIndx] = updateItem;
                setQty(qty - 1);
            } else {
                items.splice(productFoundIndx, 1);
                setQty(qty - 0);
                setAddQty(false);
            }
            localStorage.setItem('cart', JSON.stringify({ ...orderCart, items }))
        }
    };

    const addProductToCart = () => {
        const orderCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {}
        const product = {
            "itemNumber": prodDataObject.itemNumber,
            "hsn": prodDataObject.hsn,
            "uom": prodDataObject.uom,
            "perGst": prodDataObject.perGst,
            "price": prodDataObject.price,
            "icon": prodDataObject.icon,
            "qty": 1,
            "discount": "0",
            "productId": prodDataObject.id,
            "instructions": "",
            "name": prodDataObject.prodAlias
        }
        let items = JSON.parse(JSON.stringify(orderCart.items));
        items.push(product);
        localStorage.setItem('cart', JSON.stringify({ ...orderCart, items }));
        setAddQty(true);
    };

    return (
        <>
            <Row className='g-0 justify-content-center cart'>
                <Col xs={12} md={12} lg={6}>
                    <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>

                            <h5 className='mb-0 w-100 fs-6'>Product Details</h5>
                            <Button size='sm' onClick={() => handleBack()} className='bg-transparent text-dark border-0'>
                                <i class="fas fa-close"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='g-0 p-3 pt-2 card-height d-block'>
                        {dataLoaded === true ?
                            <>
                                <Col xs={12}>
                                    <Card className='cardItem mb-2'>
                                        <Card.Body>
                                            <Row className='g-0'>
                                                <Col xs={12} sm={12} className='mb-3'>
                                                    <div className='image-container overflow-hidden'>
                                                        <div className='mb-4'>
                                                            {previewLink ? (
                                                                <iframe width={300} height={300} className='rounded d-block mx-auto' src={previewLink} frameBorder="0" allowFullScreen></iframe>
                                                            ) : (
                                                                <Image width={300} height={300} className='rounded d-block mx-auto object-fit-cover' src={previewImage ? previewImage : noProductImage} />
                                                            )}
                                                        </div>
                                                        <Nav className="px-2">
                                                            <Slider className='item-nav-tabs' {...settings}>
                                                                {fileList && fileList.length > 0 ?
                                                                    fileList.map((file, idx) => (
                                                                        <Nav.Item key={idx} onClick={() => handlePreview(file.fileSrc, idx, 'image')}>
                                                                            <Nav.Link eventKey={idx}
                                                                                active={activeItemIndex === idx}
                                                                            >
                                                                                <Image width={60} height={60} className='rounded d-block mx-auto object-fit-cover' src={file.fileSrc} alt={file.fileName} />
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    )) : null}
                                                                {videoUrl !== '' ?
                                                                    <Nav.Item onClick={() => handlePreview(videoUrl, 6, 'video')}>
                                                                        <Nav.Link active={activeItemIndex === 6}>
                                                                            <iframe className='pe-none rounded d-block mx-auto object-fit-cover' width={60} height={60} src={videoUrl} frameBorder="0" allowFullScreen></iframe>
                                                                        </Nav.Link>
                                                                    </Nav.Item> : null}

                                                            </Slider>
                                                        </Nav>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} className=''>
                                                    <Card.Title className='fs-14 fw-bold mb-0'>{prodDataObject.prodAlias}</Card.Title>
                                                    <hr className='' />
                                                </Col>
                                                <Col xs={12} sm={12} className='position-relative'>

                                                    <span className='fs-14 d-flex flex-wrap align-items-center mb-2'>
                                                        <span className='me-auto pe-3'>


                                                            <div className='mb-2'>
                                                                <h4 className="fs-12 text-blue">Price</h4>
                                                                <h4 className="fs-14 fw-bold mb-0"><i class="fas fa-indian-rupee-sign fs-12"></i>{prodDataObject.price}
                                                                    <span className="fs-12 text-blue ms-2">MRP
                                                                        <span className="text-decoration-line-through ms-1">
                                                                            <i class="fas fa-indian-rupee-sign fs-12"></i>{prodDataObject.price}</span>
                                                                    </span>
                                                                </h4>
                                                            </div>
                                                        </span>

                                                        <span className='ms-auto ps-2 d-flex align-items-center'>
                                                            <Card className={`card-item addQty_card mx-auto ${addQty === true ? '' : 'bg-light-grey'}`}>
                                                                {addQty === true ?
                                                                    <>
                                                                        <Button className='border-0 bg-transparent text-dark fs-12 ms-1' onClick={() => dec()}>
                                                                            <i class="fas fa-minus"></i>
                                                                        </Button>
                                                                        <Form.Control className='bg-transparent p-0 shadow-none rounded text-center fs-14' placeholder="" value={qty} />
                                                                        <Button className='border-0 bg-transparent text-dark fs-12 me-1' onClick={() => inc()}>
                                                                            <i class="fas fa-plus"></i>
                                                                        </Button>
                                                                    </>
                                                                    :
                                                                    <Button className='border-0 bg-transparent text-dark fs-12 w-100' onClick={() => addProductToCart()}>
                                                                        <i class="fas fa-plus fs-12"></i> Add
                                                                    </Button>
                                                                }
                                                            </Card>
                                                            <div className='divider'></div>
                                                            <Button variant='primary' className='light-btn bg-white px-2 py-1 d-flex align-items-center' onClick={() => handleShareClick()}>
                                                                <i class="fa-brands fa-square-whatsapp text-green fs-3"></i>
                                                            </Button>
                                                        </span>
                                                    </span>
                                                </Col>
                                                <Col xs={12} sm={12}>
                                                    <div className='mb-2'>
                                                        <h4 className="fs-12 text-blue  mb-0">Description</h4>
                                                        <span className="fs-12">{prodDataObject.description}</span>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </>
                            :
                            <Placeholder animation="glow" as='row' className='g-0'>
                                <Card className='cardItem mb-2 bg-transparent shadow-none'>
                                    <Card.Body>
                                        <Placeholder xs={12} className='rounded mb-4' style={{ width: '100%', height: '300px' }} />
                                        {fileList && fileList.length > 0 ?
                                            <span className='mb-3' style={{ display: 'flex', gap: '7px' }}>
                                                <Placeholder className='rounded' style={{ width: '100%', height: '75px' }} />
                                                <Placeholder className='rounded' style={{ width: '100%', height: '75px' }} />
                                                <Placeholder className='rounded' style={{ width: '100%', height: '75px' }} />
                                                <Placeholder className='rounded' style={{ width: '100%', height: '75px' }} />
                                            </span> : null}
                                        <Placeholder xs={12} className='' />
                                        <Placeholder as='hr' className='' style={{ width: '100%', minHeight: '2px' }} />

                                        <span className='w-100 d-flex mt-2'>
                                            <span className='w-100'>
                                                <Placeholder xs={12} className='d-block mb-1' style={{ width: '45%' }} />
                                                <Placeholder xs={12} className='d-block mb-3' style={{ width: '55%' }} />
                                            </span>
                                            <span className='' style={{ display: 'flex', gap: '10px' }}>
                                                <Placeholder className='rounded' style={{ width: '92px', height: '33px' }} />
                                                <Placeholder className='rounded' style={{ width: '36px', height: '33px' }} />
                                            </span>
                                        </span>
                                        <Placeholder xs={12} className='d-block mb-1' style={{ width: '25%' }} />
                                        <Placeholder xs={12} className='d-block mb-1' style={{ width: '35%' }} />
                                    </Card.Body>
                                </Card>
                            </Placeholder>}
                    </Row >
                </Col>
            </Row>
        </>
    )
}


export default (ProductDetails);
