import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Badge, Button, Col, Dropdown, DropdownButton, Form, Image, Offcanvas, Row } from 'react-bootstrap';
import headsrc from '../../assets/images/b1.jpg'

const menu = {
    width: '35px',
    height: '5px',
    backgroundColor: 'black',
    margin: '6px 0'
};
// fake data generator
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}`,
        content: `${k + offset}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // margin: `0 0 16px 0`,
    borderRadius: '0.375rem',
    boxShadow: '0 1px 3px 0 #d4d9e1',

    // change background colour if dragging
    background: isDragging ? '#506070' : '#ffff',
    color: isDragging ? '#ffff' : '#000',
    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? '#e1e1e1 ' : '#e1e1e1 ',
    padding: grid,
    // width: 250
    // width: '100%',
    // height: '100%'
});

class Dashboard extends Component {
    state = {
        items: getItems(12),
        selected: getItems(12, 12),
        show: false,
    };


    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = { items };

            if (source.droppableId === 'droppable2') {
                state = { selected: items };
            }

            this.setState(state);
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                items: result.droppable,
                selected: result.droppable2
            });
        }
    };


    onOpenButton = () => {
        this.setState({
            show: true
        })
    }

    onCloseButton = () => {
        this.setState({
            show: false
        })
    }



    render() {

        const { show } = this.state;

        return (
            <>
                <Row className='g-0 justify-content-center'>
                    <Col xs={12} md={12} lg={12}>
                        {/* <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>
                            <Button size='sm' href='/checkout' className='bg-transparent text-dark border-0'>
                            <i class="fas fa-arrow-left"></i>
                        </Button>
                            <h5 className='mb-0 w-100 text-center'>Dashboard</h5>
                        </Col>
                    </Row> */}
                        {/* <Col xs={12} sm={12} style={{ borderBottom: '1px solid #506070' }}></Col> */}
                        <Row className='g-0 p-4 pb-0 mb-2' >
                            <Col xs={12} sm={12}>
                                <Row className='g-0' >
                                    <Col xs={12} sm={6} className='d-flex align-items-end'>
                                        <h5 className='mb-0 fw-bold'>Dashboard</h5>
                                    </Col>
                                    <Col xs={12} sm={6} className='text-end'>
                                        <Button size='sm' className='border-0 bg-light-blue mb-2 ms-2' onClick={this.onOpenButton}>
                                            <i class="fas fa-search"></i>
                                        </Button>
                                        <Button size='sm' className='border-0 bg-light-blue mb-2 ms-2'>
                                            <i class="fas fa-undo"></i>  Undo
                                        </Button>
                                        <Button size='sm' className='border-0 bg-light-yellow text-dark mb-2 ms-2'>
                                            <i class="fas fa-plus"></i> New Request
                                        </Button>
                                    </Col>
                                </Row>
                                <hr className='m-0' />
                            </Col>
                        </Row>
                        {/* <Row className='g-0 p-4 py-0'>
                        <Col xs={12} md={12} >
                            <Row className='g-1'>
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Col xs={12} md={7}>
                                        <Row className='g-1'>
                                            <Col xs={3} md={3}>
                                                <Row className='g-0 droppable-bg-clr'>
                                                    <Col xs={12} md={12} className='borderBottom'>
                                                        <h5 className='fs-14 my-2 d-flex align-items-center'>
                                                            <Badge className='empty-badge mx-2' bg="danger"></Badge>
                                                            Rejected (2)
                                                        </h5>
                                                    </Col>
                                                   
                                                </Row>
                                            </Col>
                                            <Col xs={6} md={6} >
                                                <Row className='g-0 droppable-bg-clr'>
                                                    <Col xs={12} md={12} className='borderBottom'>
                                                        <h5 className='fs-14 my-2 d-flex align-items-center'>
                                                            <Badge className='empty-badge mx-2' bg="dark"></Badge>
                                                            New Orders (12)
                                                        </h5>
                                                    </Col>
                                                    
                                                   
                                                </Row>
                                            </Col>
                                            <Col xs={3} md={3} >
                                                <Row className='g-0 droppable-bg-clr'>
                                                    <Col xs={12} md={12} className='borderBottom'>
                                                        <h5 className='fs-14 my-2 d-flex align-items-center'>
                                                            <Badge className='empty-badge mx-2' bg="success"></Badge>
                                                            Accepted (12)
                                                        </h5>
                                                    </Col>
                                                   
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={5}>
                                        <Row className='g-1'>
                                            <Col xs={4} md={4} >
                                                <Row className='g-0 droppable-bg-clr'>
                                                    <Col xs={12} md={12} className='borderBottom'>
                                                        <h5 className='fs-14 my-2 d-flex align-items-center'>
                                                            <Badge className='empty-badge mx-2' bg="warning"></Badge>
                                                            Processing (12)
                                                        </h5>
                                                    </Col>
                                                   
                                                </Row>
                                            </Col>
                                            <Col xs={4} md={4} >
                                                <Row className='g-0 droppable-bg-clr'>
                                                    <Col xs={12} md={12} className='borderBottom'>
                                                        <h5 className='fs-14 my-2 d-flex align-items-center'>
                                                            <Badge className='empty-badge mx-2' bg="primary"></Badge>
                                                            Ready (12)
                                                        </h5>
                                                    </Col>
                                                   
                                                </Row>
                                            </Col>
                                            <Col xs={4} md={4}>
                                                <Row className='g-0 droppable-bg-clr'>
                                                    <Col xs={12} md={12} className='borderBottom'>
                                                        <h5 className='fs-14 my-2 d-flex align-items-center'>
                                                            <Badge className='empty-badge mx-2' bg="secondary"></Badge>
                                                            Deliverd (12)
                                                        </h5>
                                                    </Col>
                                                   
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </DragDropContext>
                            </Row>
                        </Col>
                    </Row> */}
                        <Row className='g-0 p-4 py-0 dashboard-card-height '>
                            {/* <Row className='g-0 p-4 pt-0'> */}
                            {/* <Col xs={12} md={12} className='text-end'>
                            <Row className='g-0'>
                                <Col xs={12} sm={6} className=''>
                                    <h5 className='mb-0 w-100 text-start'>Dashboard</h5>
                                </Col>
                                <Col xs={12} sm={6} className=''>
                                    <Button size='sm' className='border-0 bg-light-blue mb-1 ms-2'>
                                        <i class="fas fa-search"></i>
                                    </Button>
                                </Col>
                            </Row> 
                            
                        </Col> */}
                            <Col xs={12} md={12} >
                                <Row className='g-1'>
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Col xs={12} md={7}>
                                            <Row className='g-1'>
                                                <Col xs={3} md={3}>
                                                    <Row className='g-0 droppable-bg-clr'>
                                                        <Col xs={12} md={12} className='borderBottom'>
                                                            <h5 className='fs-14 my-2 d-flex align-items-center'>
                                                                <Badge className='empty-badge mx-2' bg="danger"></Badge>
                                                                Rejected (2)
                                                            </h5>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Droppable droppableId="droppable">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}>
                                                                        {this.state.items.length > 0 &&
                                                                            this.state.items.map((item, index) => (
                                                                                <Draggable
                                                                                    key={item.id}
                                                                                    draggableId={item.id}
                                                                                    index={index}>
                                                                                    {(provided, snapshot) => (
                                                                                        <>
                                                                                            <Row className='g-0 fs-14 position-relative'
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided.draggableProps
                                                                                                        .style
                                                                                                )}>
                                                                                                <Dropdown className='dragBtn'>
                                                                                                    <Dropdown.Toggle size='sm' className='' variant="" id="dropdown-button-dark-example1">
                                                                                                        <i class="fas fa-ellipsis"></i>
                                                                                                    </Dropdown.Toggle>

                                                                                                    <Dropdown.Menu variant="">
                                                                                                        <Dropdown.Item href="#/action-1" >
                                                                                                            Action
                                                                                                        </Dropdown.Item>
                                                                                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                                {/* <Button size='sm' className='border-0 mb-1 ms-2 dragBtn'>
                                                                                                    <i class="fas fa-ellipsis"></i>
                                                                                                </Button> */}
                                                                                                <Col xs={12} sm={12} className=''>
                                                                                                    {item.content}
                                                                                                </Col>
                                                                                                <Col xs={12} sm={12}>
                                                                                                    <Row className='g-1 fs-12 fw-light'>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-indian-rupee-sign"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            780
                                                                                                        </Col>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-user"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            Custname
                                                                                                        </Col>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-phone"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            85532-45687
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} md={6} >
                                                    <Row className='g-0 droppable-bg-clr'>
                                                        <Col xs={12} md={12} className='borderBottom'>
                                                            <h5 className='fs-14 my-2 d-flex align-items-center'>
                                                                <Badge className='empty-badge mx-2' bg="dark"></Badge>
                                                                New Orders (12)
                                                            </h5>
                                                        </Col>
                                                        <Col xs={6} md={6} style={{ borderRight: '1px dashed #f5f5f8' }}>
                                                            <Droppable droppableId="droppable">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}>
                                                                        {this.state.items.length > 0 &&
                                                                            this.state.items.map((item, index) => (
                                                                                <Draggable
                                                                                    key={item.id}
                                                                                    draggableId={item.id}
                                                                                    index={index}>
                                                                                    {(provided, snapshot) => (
                                                                                        <>
                                                                                            <Row className='g-0 fs-14 position-relative'
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided.draggableProps
                                                                                                        .style
                                                                                                )}>
                                                                                                <Dropdown className='dragBtn'>
                                                                                                    <Dropdown.Toggle size='sm' className='' variant="" id="dropdown-button-dark-example1">
                                                                                                        <i class="fas fa-ellipsis"></i>
                                                                                                    </Dropdown.Toggle>

                                                                                                    <Dropdown.Menu variant="">
                                                                                                        <Dropdown.Item href="#/action-1" >
                                                                                                            Action
                                                                                                        </Dropdown.Item>
                                                                                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                                <Col xs={12} sm={12} className=''>
                                                                                                    {item.content}
                                                                                                </Col>
                                                                                                <Col xs={12} sm={12}>
                                                                                                    <Row className='g-1 fs-12 fw-light'>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-indian-rupee-sign"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            780
                                                                                                        </Col>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-user"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            Custname
                                                                                                        </Col>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-phone"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            85532-45687
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Droppable droppableId="droppable2">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}>
                                                                        {this.state.selected.map((item, index) => (
                                                                            <Draggable
                                                                                key={item.id}
                                                                                draggableId={item.id}
                                                                                index={index}>
                                                                                {(provided, snapshot) => (
                                                                                    <>
                                                                                        <Row className='g-0 fs-14 position-relative'
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )}>
                                                                                            <Dropdown className='dragBtn'>
                                                                                                <Dropdown.Toggle size='sm' className='' variant="" id="dropdown-button-dark-example1">
                                                                                                    <i class="fas fa-ellipsis"></i>
                                                                                                </Dropdown.Toggle>

                                                                                                <Dropdown.Menu variant="">
                                                                                                    <Dropdown.Item href="#/action-1" >
                                                                                                        Action
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                            <Col xs={12} sm={12} className=''>
                                                                                                {item.content}
                                                                                            </Col>
                                                                                            <Col xs={12} sm={12}>
                                                                                                <Row className='g-1 fs-12 fw-light'>
                                                                                                    <Col xs={2} className=''>
                                                                                                        <i class="fas fa-indian-rupee-sign"></i>
                                                                                                    </Col>
                                                                                                    <Col xs={10} className=''>
                                                                                                        780
                                                                                                    </Col>
                                                                                                    <Col xs={2} className=''>
                                                                                                        <i class="fas fa-user"></i>
                                                                                                    </Col>
                                                                                                    <Col xs={10} className=''>
                                                                                                        Custname
                                                                                                    </Col>
                                                                                                    <Col xs={2} className=''>
                                                                                                        <i class="fas fa-phone"></i>
                                                                                                    </Col>
                                                                                                    <Col xs={10} className=''>
                                                                                                        85532-45687
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </>
                                                                                )}
                                                                            </Draggable>
                                                                        ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={3} md={3} >
                                                    <Row className='g-0 droppable-bg-clr'>
                                                        <Col xs={12} md={12} className='borderBottom'>
                                                            <h5 className='fs-14 my-2 d-flex align-items-center'>
                                                                <Badge className='empty-badge mx-2' bg="success"></Badge>
                                                                Accepted (12)
                                                            </h5>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Droppable droppableId="droppable">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}>
                                                                        {this.state.items.length > 0 &&
                                                                            this.state.items.map((item, index) => (
                                                                                <Draggable
                                                                                    key={item.id}
                                                                                    draggableId={item.id}
                                                                                    index={index}>
                                                                                    {(provided, snapshot) => (
                                                                                        <>
                                                                                            <Row className='g-0 fs-14 position-relative'
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided.draggableProps
                                                                                                        .style
                                                                                                )}>
                                                                                                <Dropdown className='dragBtn'>
                                                                                                    <Dropdown.Toggle size='sm' className='' variant="" id="dropdown-button-dark-example1">
                                                                                                        <i class="fas fa-ellipsis"></i>
                                                                                                    </Dropdown.Toggle>

                                                                                                    <Dropdown.Menu variant="">
                                                                                                        <Dropdown.Item href="#/action-1" >
                                                                                                            Action
                                                                                                        </Dropdown.Item>
                                                                                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                                <Col xs={12} sm={12} className=''>
                                                                                                    {item.content}
                                                                                                </Col>
                                                                                                <Col xs={12} sm={12}>
                                                                                                    <Row className='g-1 fs-12 fw-light'>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-indian-rupee-sign"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            780
                                                                                                        </Col>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-user"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            Custname
                                                                                                        </Col>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-phone"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            85532-45687
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={5}>
                                            <Row className='g-1'>
                                                <Col xs={4} md={4} >
                                                    <Row className='g-0 droppable-bg-clr'>
                                                        <Col xs={12} md={12} className='borderBottom'>
                                                            <h5 className='fs-14 my-2 d-flex align-items-center'>
                                                                <Badge className='empty-badge mx-2' bg="warning"></Badge>
                                                                Processing (12)
                                                            </h5>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Droppable droppableId="droppable">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}>
                                                                        {this.state.items.length > 0 &&
                                                                            this.state.items.map((item, index) => (
                                                                                <Draggable
                                                                                    key={item.id}
                                                                                    draggableId={item.id}
                                                                                    index={index}>
                                                                                    {(provided, snapshot) => (
                                                                                        <>
                                                                                            <Row className='g-0 fs-14 position-relative'
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided.draggableProps
                                                                                                        .style
                                                                                                )}>
                                                                                                <Dropdown className='dragBtn'>
                                                                                                    <Dropdown.Toggle size='sm' className='' variant="" id="dropdown-button-dark-example1">
                                                                                                        <i class="fas fa-ellipsis"></i>
                                                                                                    </Dropdown.Toggle>

                                                                                                    <Dropdown.Menu variant="">
                                                                                                        <Dropdown.Item href="#/action-1" >
                                                                                                            Action
                                                                                                        </Dropdown.Item>
                                                                                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                                <Col xs={12} sm={12} className=''>
                                                                                                    {item.content}
                                                                                                </Col>
                                                                                                <Col xs={12} sm={12}>
                                                                                                    <Row className='g-1 fs-12 fw-light'>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-indian-rupee-sign"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            780
                                                                                                        </Col>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-user"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            Custname
                                                                                                        </Col>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-phone"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            85532-45687
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={4} md={4} >
                                                    <Row className='g-0 droppable-bg-clr'>
                                                        <Col xs={12} md={12} className='borderBottom'>
                                                            <h5 className='fs-14 my-2 d-flex align-items-center'>
                                                                <Badge className='empty-badge mx-2' bg="primary"></Badge>
                                                                Ready (12)
                                                            </h5>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Droppable droppableId="droppable">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}>
                                                                        {this.state.items.length > 0 &&
                                                                            this.state.items.map((item, index) => (
                                                                                <Draggable
                                                                                    key={item.id}
                                                                                    draggableId={item.id}
                                                                                    index={index}>
                                                                                    {(provided, snapshot) => (
                                                                                        <>
                                                                                            <Row className='g-0 fs-14 position-relative'
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided.draggableProps
                                                                                                        .style
                                                                                                )}>
                                                                                                <Dropdown className='dragBtn'>
                                                                                                    <Dropdown.Toggle size='sm' className='' variant="" id="dropdown-button-dark-example1">
                                                                                                        <i class="fas fa-ellipsis"></i>
                                                                                                    </Dropdown.Toggle>

                                                                                                    <Dropdown.Menu variant="">
                                                                                                        <Dropdown.Item href="#/action-1" >
                                                                                                            Action
                                                                                                        </Dropdown.Item>
                                                                                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                                <Col xs={12} sm={12} className=''>
                                                                                                    {item.content}
                                                                                                </Col>
                                                                                                <Col xs={12} sm={12}>
                                                                                                    <Row className='g-1 fs-12 fw-light'>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-indian-rupee-sign"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            780
                                                                                                        </Col>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-user"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            Custname
                                                                                                        </Col>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-phone"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            85532-45687
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={4} md={4}>
                                                    <Row className='g-0 droppable-bg-clr'>
                                                        <Col xs={12} md={12} className='borderBottom'>
                                                            <h5 className='fs-14 my-2 d-flex align-items-center'>
                                                                <Badge className='empty-badge mx-2' bg="secondary"></Badge>
                                                                Deliverd (12)
                                                            </h5>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Droppable droppableId="droppable">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}>
                                                                        {this.state.items.length > 0 &&
                                                                            this.state.items.map((item, index) => (
                                                                                <Draggable
                                                                                    key={item.id}
                                                                                    draggableId={item.id}
                                                                                    index={index}>
                                                                                    {(provided, snapshot) => (
                                                                                        <>
                                                                                            <Row className='g-0 fs-14 position-relative'
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided.draggableProps
                                                                                                        .style
                                                                                                )}>
                                                                                                <Dropdown className='dragBtn'>
                                                                                                    <Dropdown.Toggle size='sm' className='' variant="" id="dropdown-button-dark-example1">
                                                                                                        <i class="fas fa-ellipsis"></i>
                                                                                                    </Dropdown.Toggle>

                                                                                                    <Dropdown.Menu variant="">
                                                                                                        <Dropdown.Item href="#/action-1" >
                                                                                                            Action
                                                                                                        </Dropdown.Item>
                                                                                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                                <Col xs={12} sm={12} className=''>
                                                                                                    {item.content}
                                                                                                </Col>
                                                                                                <Col xs={12} sm={12}>
                                                                                                    <Row className='g-1 fs-12 fw-light'>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-indian-rupee-sign"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            780
                                                                                                        </Col>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-user"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            Custname
                                                                                                        </Col>
                                                                                                        <Col xs={2} className=''>
                                                                                                            <i class="fas fa-phone"></i>
                                                                                                        </Col>
                                                                                                        <Col xs={10} className=''>
                                                                                                            85532-45687
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </DragDropContext>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>


                <Offcanvas className='bg-transparent border-0' style={{ height: '40vh' }} placement='top' show={show}>
                    {/* <Offcanvas.Header closeButton>
                        <Offcanvas.Title className='fs-14 fw-bold'></Offcanvas.Title>
                    </Offcanvas.Header> */}
                    <Offcanvas.Body>
                        <Row className='g-2 mt-5'>
                            <Col xs={12} md={8} className='d-flex align-items-center mx-auto'>
                                <div className="search-Box">
                                    <Button size='sm' className='searchIcon' onClick={this.onClickButton}>
                                        <i class="fas fa-search"></i>
                                    </Button>
                                    <Form.Control type='search' className='formInput' placeholder="Search..." aria-label="search" aria-describedby="basic-addon1" />
                                </div>
                                <Button size='' className='bg-transparent border-0 fs-5 text-danger' onClick={this.onCloseButton}>
                                    <i class="fas fa-close"></i>
                                </Button>
                            </Col>
                            {/* <Col xs={12} md={9} className='mx-auto text-end'>
                                    <Button size='sm' className='bg-light-blue'>
                                        Close
                                    </Button>
                            </Col> */}

                        </Row>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }
}

export default Dashboard;