import React, { useEffect } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import logo from '../../assets/images/mf-logo.png';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import noImage from '../../assets/images/no-image.png';

const WelcomeScreen = () => {

    let navigate = useNavigate();

    useEffect(() => {
        let token = Cookies.get("E_COMM_TOKEN");
        if (token) {
            navigate('/home');
        } else {
            navigate('/');
        }
    }, [navigate]);

    return (
        <>
            <Row className='g-0 justify-content-center bg-welcScreen'>
                <Col xs={12} md={12} lg={6}>
                    <Row className='g-0 p-3 welcFoodImage'>
                        <Col xs={12} className='d-flex align-items-center mt-4'>
                            <span className='bg-logo'>
                                <Image width='100%' className='logo' src={logo || noImage} />
                            </span>
                        </Col>
                        <Col xs={12} md={12} className='mt-4'>
                            <h5 className='foodFont'>Food For <br />Everyone</h5>
                        </Col>
                    </Row>
                    <Row className='g-0 bottom_card'>
                        <Col xs={12} lg={6} className='bottom_btn'>
                            <Row className='g-0'>
                                <Col xs={12} className='px-3'>
                                    <p className='text-center fs-14'> already have an account ! <a href='/home' className='fs-6'>sign in</a></p>
                                </Col>
                                <Col xs={12} className='px-3'>
                                    <Button onClick={() => navigate('/enternumber')} className='w-100 py-3 foot-btn bg-light-blue text-truncate shadow-none'>
                                        Sign up with number
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}


export default (WelcomeScreen);
