import React from 'react';
import { Accordion, Button, Card, Col, Form, Image, ListGroup, Row } from 'react-bootstrap';
// import newCard from '../../assets/images/credit-card.png';
// import upiImg from '../../assets/images/upi.png';
// import gPay from '../../assets/images/google-pay.png';
// import phonePe from '../../assets/images/phonepe-logo-icon.png';
// import cashOn from '../../assets/images/money.png';



class Location extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locationList: [
                { name: 'Moga Bus Stand', nameAdd: 'Ferozepur-Rd, Moga, Punjab 142001, India' },
                { name: 'Moga College Of Distance Education', nameAdd: 'Opp. Civil Hospital Main Bazar, Aara Rd, Krishna Nagar, Moga, Punjab 142001, India' },
                { name: 'RAJ BOUTIQUE', nameAdd: '3, Dusehra Ground Rd, Moga, Punjab 142001, India' },
                { name: 'GK Group Enterprises', nameAdd: 'GK Group Enterprises, Aara Road Moga, Moga, Punjab 142001, India' },
                { name: 'ICICI Bank ATM', nameAdd: 'Kotkapura - Moga Rd, Krishna Nagar, Moga, Punjab 142001, India' },
                { name: 'State Bank ATM', nameAdd: 'Main Bazzar Moga, Main Bazzar, OPP Shivala, Moga, Punjab 142001, India' },

            ]
        };
    }


    render() {
        const { locationList } = this.state;
        return (
            <>
                <Row className='g-0 justify-content-center location'>
                    <Col xs={12} md={12} lg={6}>
                        <Row className='g-0 p-3 '>
                            <Col xs={12} className='d-flex align-items-center'>
                                <Button size='sm' href='/home' className='bg-transparent text-dark border-0'>
                                    <i class="fas fa-arrow-left"></i>
                                </Button>
                                <h5 className='mb-0 w-100 text-center pe-4 fs-6'>Choose Location</h5>
                            </Col>
                        </Row>
                        <Row className='g-0 p-3 pt-2 d-block'>
                            <Col xs={12}>
                                <Card className='cardItem mb-2' role='button'>
                                    <Card.Body className=''>
                                        <Row className='g-0'>
                                            <Col xs={12} className='d-flex align-items-center fs-14'>
                                                <p className='mb-0'>Use Current Location</p>
                                                <Button size='sm' className='arrow-right fs-5 ms-auto' >
                                                    <i class="fas fa-location"></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={12} className='mt-3'>
                                <h5 className='fs-14 fw-bold'>Nearby Location   <i class="fas fa-map-location-dot ms-2"></i></h5>
                            </Col>
                            <Col xs={12}>
                                <Card className='cardItem mb-2'>
                                    <Card.Body className='px-0 py-2'>
                                        <ListGroup variant="flush" className='location-card-height fs-14'>
                                            {locationList.map((item) => {
                                                return (
                                                    <>
                                                        <ListGroup.Item role='button'>
                                                            {item.name}
                                                            <p className='mb-0 fs-12'>{item.nameAdd}</p>
                                                        </ListGroup.Item>
                                                    </>
                                                )
                                            })}
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}


export default (Location);
