// import { SESSION_KEYS } from "./constants";

import Cookies from "js-cookie"

//Headers to access API data 
export const HEADERS = {
    BASIC: () => {
        return {
            'Accept': 'application/json,text/plain',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        }
    },
    LOGIN: (besicData) => {
        return {
            'Accept': 'application/json,text/plain',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${besicData}`,
            'Cache-Control': 'no-cache'
        }
    },
    AUTHENTIC: () => {
        return {
            'Accept': 'application/json,text/plain',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token()}`,
            'Cache-Control': 'no-cache'
        }
    },
    FILE: () => {
        return {
            'Accept': 'application/json,text/plain',
            'Content-Type': '',
            'Authorization': `Bearer ${token()}`,
            'Cache-Control': 'no-cache',
        }
    }

}

const token = () => {
    return Cookies.get("E_COMM_TOKEN");
}