import React, { useEffect, useState } from 'react';
import { Button, Card, Col, FloatingLabel, Form, Image, Offcanvas, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

function ReviewModal(props) {
    const dispatch = useDispatch();
    const [ratingText, setRatingText] = useState([]);
    const [hover, setHover] = useState(0);
    const [actionButton, setActionButton] = useState(false);
    const [showExitReviewModal, setShowExitReviewModal] = useState(false);

    const ratingLength = [...Array(5)];

    const getRatingText = (ratingText) => {
        if (ratingText === 1) {
            return "Very Poor";
        } else if (ratingText === 2) {
            return "Poor";
        } else if (ratingText === 3) {
            return "Average";
        } else if (ratingText === 4) {
            return "Good";
        } else if (ratingText === 5) {
            return "Excellent";
        } else {
            return "...";
        }
    };

    const handleButton = (name) => {
        setActionButton(name);
    };


    return (
        <>

            <Offcanvas className='home-offcan offcanvas_bottom' placement='bottom' show={props.showReviewModal} onHide={props.onOpenExitReviewModal} style={{ height: 'fit-content' }}>
                <Offcanvas.Body>
                    <Row className='g-0 justify-content-center'>
                        <Col xs={12} className='d-flex flex-wrap align-items-center '>
                            {/* <Offcanvas.Title className='fs-14 fw-bold me-auto'></Offcanvas.Title> */}
                            <Button className='bg-transparent border-0 shadow-none text-dark' style={{ position: 'absolute', top: 0, right: 0 }} onClick={props.onOpenExitReviewModal}>
                                <i class="fas fa-close"></i>
                            </Button>
                        </Col>
                        <Col xs={12} md={12} lg={6} className='reviewModal'>
                            <Row className='g-0'>
                                <Col xs={12} md={12} className='text-center mt-4'>
                                    {ratingText && (
                                        <h5 className='fs-14 fw-bold'>{getRatingText(ratingText)}</h5>
                                    )}
                                </Col>
                                <Col xs={12} className='text-center mb-2'>
                                    {ratingLength.map((star, index) => {
                                        const ratingData = index + 1;
                                        return (
                                            <label key={index} className='star_rating_icon'>
                                                <input type="radio" name="rating" value={ratingData} onClick={() => setRatingText(ratingData)} />
                                                <i className={`${ratingData <= (hover || ratingText) ? "fas" : "far"} fa-star star_icon`}
                                                    style={{ color: ratingData <= (hover || ratingText) ? "#fdc208" : "#d5d5d5" }}
                                                    onMouseEnter={() => setHover(ratingData)} onMouseLeave={() => setHover(0)} />
                                            </label>
                                        );
                                    })}
                                    {/* {ratingText && (
                                            <p className='fw-bold'>{getRatingText(ratingText)}</p>
                                        )} */}
                                </Col>
                                <Col xs={12} md={12} className='mt-3'>
                                    <h5 className='fs-14 fw-bold'>You impressed ?</h5>
                                </Col>
                                <Col xs={12} md={12} className=''>
                                    <Card className='cardItem' >
                                        <Card.Body className='p-2'>
                                            <Row className='g-2 py-2'>
                                                <Col xs={6} className=''>
                                                    <Button variant='primary' className={`btn btn-primary light-btn border shadow-none w-100 fs-12 ${actionButton === 'FQ' ? 'bg-yellow text-dark' : 'bg-white text-blue'}`} onClick={() => handleButton('FQ')}>
                                                        Food Quality
                                                    </Button>
                                                </Col>
                                                <Col xs={6} className=''>
                                                    <Button variant='primary' className={`btn btn-primary light-btn border shadow-none w-100 fs-12 ${actionButton === 'PS' ? 'bg-yellow text-dark' : 'bg-white text-blue'}`} onClick={() => handleButton('PS')}>
                                                        Portion Size
                                                    </Button>
                                                </Col>
                                                <Col xs={6} className=''>
                                                    <Button variant='primary' className={`btn btn-primary light-btn border shadow-none w-100 fs-12 ${actionButton === 'PK' ? 'bg-yellow text-dark' : 'bg-white text-blue'}`} onClick={() => handleButton('PK')}>
                                                        Packing
                                                    </Button>
                                                </Col>
                                                <Col xs={6} className=''>
                                                    <Button variant='primary' className={`btn btn-primary light-btn border shadow-none w-100 fs-12 ${actionButton === 'VM' ? 'bg-yellow text-dark' : 'bg-white text-blue'}`} onClick={() => handleButton('VM')}>
                                                        Value for money
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={12} className='mt-3'>
                                    <h5 className='fs-14 fw-bold'>Add Review</h5>
                                </Col>
                                <Col xs={12} md={12} className=''>
                                    <Card className='cardItem' >
                                        <Card.Body className='p-2'>
                                            <Row className='g-0 pt-2 '>
                                                <Col xs={12} className=''>
                                                    <FloatingLabel
                                                        controlId="floatingTextarea"
                                                        label="write here"
                                                        className="border mb-2 rounded"
                                                    >
                                                        <Form.Control as="textarea" placeholder="Leave a comment here" style={{ height: '100px' }} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12} className=''>
                                                    <Button size='sm' className='bg-transparent text-red border-0 fs-12' >
                                                        <i class='fas fa-camera me-1 fs-14'></i>Add photo
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='g-0 position-relative'>
                                <Col xs={12} lg={6} className='px-3 btn-position'>
                                    {/* {dataLoaded === true ? */}
                                    <Button className='w-100 py-3 foot-btn bg-light-blue text-truncate' onClick={props.onOpenSuccessModal}>
                                        Submit
                                    </Button>
                                    {/* :
                                <Placeholder animation="glow" as='row' className='g-0'>
                                    <Placeholder.Button xs={12} className='rounded' style={{ height: '56px' }} />
                                </Placeholder>} */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Offcanvas.Body >
            </Offcanvas >
        </>
    )
}


export default (ReviewModal);
