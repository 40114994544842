import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Home from './components/home/Home';
import './App.css';
import { BrowserRouter, Routes, Route, redirect } from "react-router-dom";
import Cart from './components/cart/Cart';
import CheckOut from './components/checkout/CheckOut';
import Payment from './components/payment/Payment';
import WelcomeScreen from './components/welcomescreen/WelcomeScreen';
import Location from './components/location/Location';
import Dashboard from './components/Dashboard/Dashboard';
import EnterNumber from './components/EnterMobileNumber/EnterNumber';
import EnterCodeOTP from './components/EnterCodeOTP/EnterCodeOTP';
import OrderHistory from './components/orderhistory/OrderHistory';
import OrderSummary from './components/ordersummary/OrderSummary';
import ChooseBrands from './components/choosebrands/ChooseBrands';
import ProductDetails from './components/productdetails/ProductDetails';
import StartPage from './components/StartPage/StartPage';
import BrandPage from './components/BrandPage/BrandPage';
import OTPList from './components/OTPS/OTPList';
import BrandInformation from './components/brandinformation/BrandInformation';
import YourProfile from './components/yourprofile/YourProfile';

function App() {

  useEffect(() => {
    currentPageARoute();
  }, []);

  const currentPageARoute = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('code')) {
      return redirect('/business');
    } else {
      return redirect('/');
    }
  }

  return (
    <>
      <Container fluid className='p-0 m-0'>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route path="/business" element={<BrandPage />} />
            <Route path="/scan" element={<ChooseBrands />} />
            <Route path="/welcomescreen" element={<WelcomeScreen />} />
            <Route path="/home" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/location" element={<Location />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/enternumber' element={<EnterNumber />} />
            <Route path='/entercode' element={<EnterCodeOTP />} />
            <Route path='/orderhistory' element={<OrderHistory />} />
            <Route path='/ordersummary' element={<OrderSummary />} />
            <Route path='/choosebrand' element={<ChooseBrands />} />
            <Route path='/productdetails' element={<ProductDetails />} />
            <Route path='/otps' element={<OTPList />} />
            <Route path='/brandinformation' element={<BrandInformation />} />
            <Route path='/yourprofile' element={<YourProfile />} />
          </Routes>
        </BrowserRouter>
      </Container>
    </>
  );
}

export default (App);
