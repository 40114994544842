import React, { useEffect, useState } from 'react';
import { Button, Card, Col, ListGroup, Modal, Row, Tab, } from 'react-bootstrap';
function DineModal(props) {

    const [seat, setSeat] = useState([
        "A1",
        "A2",
        "A3",
        "A4",
        "A5",
        "A6",
        "A7",
        "A8",
        "A9",
        "A10",

        "B1",
        "B2",
        "B3",
        "B4",
        "B5",
        "B6",
        "B7",
        "B8",
        "B9",
        "B10",

        "C1",
        "C2",
        "C3",
        "C4",
        "C5",
        "C6",
        "C7",
        "C8",
        "C9",
        "C10",
    ]);
    const [seatAvailable, setSeatAvailable] = useState([
        "A1",
        "A2",
        "A3",
        "A4",
        "A5",
        "A6",
        "A7",
        "A8",
        "A9",
        "A10",

        "B1",
        "B2",
        "B3",
        "B4",
        "B5",
        "B6",
        "B7",
        "B8",
        "B9",
        "B10",

        "C1",
        "C2",
        "C3",
        "C4",
        "C5",
        "C6",
        "C7",
        "C8",
        "C9",
        "C10",
    ]);
    const [seatReserved, setSeatReserved] = useState([]);
    const [seatSelected, setSeatSelected] = useState(["A1", "B2"]);
    const [deliveryMethodIndex, setDeliveryMethodIndex] = useState(0);
    const [cart, setCart] = useState({});

    const onClickData = (seat) => {
        if (seatReserved.indexOf(seat) > -1) {
            setSeatAvailable(seatAvailable.concat(seat));
            setSeatAvailable(seatReserved.filter(res => res != seat));
        } else {
            setSeatAvailable(seatAvailable.concat(seat));
            setSeatAvailable(seatReserved.filter(res => res != seat));
        }
    }

    useEffect(() => {
        if (localStorage.getItem('cart')) {
            getCartDetails();
        }
    }, []);

    const getCartDetails = () => {
        let cart = JSON.parse(localStorage.getItem('cart'));
        if (cart) {
            let itemIndex = props.deliveryMethods.findIndex(dm => dm.methodCode === cart.deliveryMethod);
            if (itemIndex > -1) {
                setDeliveryMethodIndex(itemIndex);
            }
        }
    }

    const onClickSeat = (seat) => {
        onClickData(seat);
    }

    const changeOrderType = (orderType, index) => {
        setDeliveryMethodIndex(index);
        props.switchOrderType(orderType);
    }

    return (
        <>

            <Modal show={props.showModal} centered size='lg' className='my_modal' >
                <Modal.Body>
                    <Row className='g-0 fs-14'>
                        <Col xs={12} className='text-end'>
                            <Button className='bg-transparent border-0 shadow-none text-dark' onClick={props.onCloseModalBtn}>
                                <i class="fas fa-close"></i>
                            </Button>
                        </Col>
                        <Col xs={12}>
                            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#one">
                                <Row className='g-2'>
                                    <Col xs={4} sm={4} md={4} lg={3}>
                                        <div className='category_list_left pb-0'>
                                            <ListGroup>
                                                {/* <div className='list-group-scroll'> */}
                                                {props.deliveryMethods && props.deliveryMethods.length > 0 && props.deliveryMethods.map((deliveryMethod, index) => {
                                                    return (
                                                        <ListGroup.Item key={index} action href="#one" className='p-3' onClick={() => changeOrderType(deliveryMethod.methodCode, index)} active={deliveryMethodIndex === index}>
                                                            <span className='right_border'>abc</span>
                                                            <span className='d-md-flex flex-wrap flex-md-nowrap d-block align-items-center'>
                                                                <div className=' pe-0 pe-md-3 mb-1 mb-md-0 text-center'>
                                                                    {/* <Image width={40} className='' src={dine} /> */}
                                                                    <span className={deliveryMethod.methodCode == "DINE" ? 'list_img_one' : deliveryMethod.methodCode === "PICK" ? 'list_img_three' : deliveryMethod.methodCode === "DELIVERY" ? 'list_img_two' : deliveryMethod.methodCode === "DRIVE" ? 'list_img_four' : ''} />
                                                                </div>
                                                                <span className='fs-14 text-truncate d-block text-center me-md-auto me-0'>
                                                                    {deliveryMethod.name}
                                                                </span>
                                                            </span>
                                                        </ListGroup.Item>
                                                    )
                                                })}
                                                {/* <ListGroup.Item action href="#two" className='p-3' onClick={() => changeOrderType("DELIVERY")}>
                                                    <span className='right_border'>abc</span>
                                                    <span className='d-md-flex flex-wrap flex-md-nowrap d-block align-items-center'>
                                                        <div className=' pe-0 pe-md-3 mb-1 mb-md-0 text-center'> */}
                                                {/* <Image width={40} className='' src={delivery} /> */}
                                                {/* <span className='list_img_two' />
                                                        </div>
                                                        <span className='fs-14 text-truncate d-block text-center me-md-auto me-0'>
                                                            DELIVERY
                                                        </span>
                                                    </span>
                                                </ListGroup.Item> */}
                                                {/* <ListGroup.Item action href="#three" className='p-3' onClick={() => changeOrderType("PICK")}>
                                                    <span className='right_border'>abc</span>
                                                    <span className='d-md-flex flex-wrap flex-md-nowrap d-block align-items-center'>
                                                        <div className=' pe-0 pe-md-3 mb-1 mb-md-0 text-center'> */}
                                                {/* <Image width={40} className='' src={pick} /> */}
                                                {/* <span className='list_img_three' />
                                                        </div>
                                                        <span className='fs-14 text-truncate d-block text-center me-md-auto me-0'>
                                                            PICK
                                                        </span>
                                                    </span>
                                                </ListGroup.Item> */}
                                                {/* <ListGroup.Item action href="#four" className='p-3' onClick={() => changeOrderType("DRIVE")}>
                                                    <span className='right_border'>abc</span>
                                                    <span className='d-md-flex flex-wrap flex-md-nowrap d-block align-items-center'>
                                                        <div className=' pe-0 pe-md-3 mb-1 mb-md-0 text-center'> */}
                                                {/* <Image width={40} className='' src={drive} /> */}
                                                {/* <span className='list_img_four' />
                                                        </div>
                                                        <span className='fs-14 text-truncate d-block text-center me-md-auto me-0'>
                                                            DRIVE
                                                        </span>
                                                    </span>
                                                </ListGroup.Item> */}
                                                {/* </div> */}
                                            </ListGroup>
                                        </div>
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={9} >
                                        <Tab.Content className='h-100'>
                                            {/* <Tab.Pane eventKey="#one" className='h-100'>
                                                <Row className='g-0 h-100'>
                                                    <Col xs={12} className='h-100'>
                                                        <Card className='cardItem h-100' >
                                                            <Card.Body className='p-2'>
                                                                <Row className='g-0 fs-14'>
                                                                    <Col xs={12} className='mt-2'>
                                                                        <Card.Title className='fs-14 fw-bold'>Seat Reservation System</Card.Title>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <table className="grid_seat mb-3">
                                                                            <tbody>
                                                                                <tr>
                                                                                    {seat.map(row => (
                                                                                        <td
                                                                                            className={
                                                                                                seatSelected.indexOf(row) > -1 ? "reserved"
                                                                                                    : seatReserved.indexOf(row) > -1 ? "selected" : "available"}
                                                                                            key={row}
                                                                                            onClick={
                                                                                                checktrue(row)
                                                                                                    ? e => onClickSeat(row) : null}>
                                                                                            {row}{" "}
                                                                                        </td>
                                                                                    ))}
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <Button variant='primary' className='light-btn bg-light-blue text-white' type="button" onClick={() => changeOrderType("DINE")}>
                                                                            Confirm Booking
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="#two" className='h-100'>
                                                <Row className='g-0 h-100' >
                                                    <Col xs={12} className='h-100'>
                                                        <Card className='cardItem h-100' >
                                                            <Card.Body className='p-2'>
                                                                <Row className='g-0 fs-14'>
                                                                    <Col xs={12} className='mt-2'>
                                                                        <Card.Title className='fs-14 fw-bold'>Delivery</Card.Title>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="#three" className='h-100'>
                                                <Row className='g-0 h-100'>
                                                    <Col xs={12} className='h-100'>
                                                        <Card className='cardItem h-100' >
                                                            <Card.Body className='p-2'>
                                                                <Row className='g-0 fs-14'>
                                                                    <Col xs={12} className='mt-2'>
                                                                        <Card.Title className='fs-14 fw-bold'>Pick</Card.Title>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="#four" className='h-100'>
                                                <Row className='g-0 h-100'>
                                                    <Col xs={12} className='h-100'>
                                                        <Card className='cardItem h-100' >
                                                            <Card.Body className='p-2'>
                                                                <Row className='g-0 fs-14'>
                                                                    <Col xs={12} className='mt-2'>
                                                                        <Card.Title className='fs-14 fw-bold'>Drive</Card.Title>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane> */}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row >
                </Modal.Body >
            </Modal >
        </>
    )
}

export default (DineModal);
