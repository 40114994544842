import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Image, Offcanvas, Row } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { getStoreList } from '../../actions/DashBoard/getDashBoardData';
import noImage from '../../assets/images/no-image.png';

function BrandModal(props) {

    const baseUrl = localStorage.getItem('baseUrl');

    const dispatch = useDispatch();
    const [brandItem, setBrandItem] = useState([]);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        getStores(Cookies.get('location'));
    }, []);

    const getStores = (location) => {

        let prepareGeometry = location;
        try {
            dispatch(getStoreList(prepareGeometry)).then((list) => {
                if (list.payload) {
                    let stores = JSON.parse(JSON.stringify(list.payload));
                    setBrandItem(stores);
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    const handleInputChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    }

    const chooseStore = (store) => {
        Cookies.set('store', JSON.stringify(store), { expires: 30 });
        props.onCloseButton(store);
    }

    return (
        <>

            <Offcanvas className='home-offcan' placement='start' show={props.showBrand} onHide={props.onCloseBrandModal}>
                <Offcanvas.Header>
                    <Row className='g-0 w-100'>
                        <Col xs={12} className='d-flex flex-wrap align-items-center ' key={1}>
                            <Offcanvas.Title className='mb-0 pe-4 fs-6'>Top Brands</Offcanvas.Title>
                            <Button size='sm' className='ms-auto bg-transparent border-0 shadow-none text-dark' onClick={props.onCloseBrandModal}>
                                <i class="fas fa-close"></i>
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Header>
                <Offcanvas.Body className='pt-2'>
                    <Row className='g-0'>
                        {/* <Col xs={12} className='d-flex flex-wrap align-items-center ' key={1}>
                            <Offcanvas.Title className='fs-14 fw-bold me-auto'>Top Brands</Offcanvas.Title>
                            <Button className='bg-transparent border-0 shadow-none text-dark' onClick={props.onCloseBrandModal}>
                                <i class="fas fa-close"></i>
                            </Button>
                        </Col> */}
                        <Col xs={12} className='mb-3' key={2}>
                            <div className="searchBox ">
                                <Button size='sm' className='search-icon'>
                                    <i class="fas fa-search"></i>
                                </Button>
                                <Form.Control className='form-input pe-3 shadow-sm' placeholder="Search brands" type='search' aria-label="search" aria-describedby="basic-addon1" value={filter} id="myInput" onChange={handleInputChange} />
                            </div>
                        </Col>
                        <Col xs={12} key={3}>
                            <Row className='g-2'>
                                {brandItem.map((item, idx) => {
                                    const { name, type } = item;
                                    if (!name) return null;
                                    const txtValue = name.toLowerCase();
                                    const displayStyle = txtValue.indexOf(filter) > -1 ? '' : 'none';
                                    return (
                                        <>
                                            <Col xs={6} key={idx} style={{ display: displayStyle }} onClick={() => chooseStore(item)}>
                                                <Button variant='primary' className='light-btn w-100 p-3'>
                                                    <Image width={60} className='mx-auto mb-3 d-block' src={baseUrl + "/" + item.storeLogo || noImage} />
                                                    <h3 className='fs-14 mb-0'>{item.name}</h3>
                                                </Button>
                                            </Col>
                                        </>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Offcanvas.Body >
            </Offcanvas>
        </>
    )
}


export default (BrandModal);
