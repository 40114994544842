import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, ListGroup, Offcanvas, Row, } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getStoreList } from '../../actions/DashBoard/getDashBoardData';
import { fetchLocationBySearchText, fetchPlaceDetailByID, fetchStoreByKeyCode, loginWithGuest } from '../../slice/dashBoardSlice';
import Cookies from 'js-cookie';

function MapModal(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [brandItem, setBrandItem] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [locations, setLocations] = useState([]);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        // if (Cookies.get('location')) {
        //     getStores(Cookies.get('location'));
        // } else {
        //     setShowMap(true);
        // }
    }, []);

    const getStores = (location) => {

        let prepareGeometry = location;
        dispatch(getStoreList(prepareGeometry)).then((list) => {
            let stores = JSON.parse(JSON.stringify(list.payload));
            setBrandItem(stores);
        })
    }

    const handleInputChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    }

    const onChangeLocation = (e) => {
        dispatch(fetchLocationBySearchText(e.target.value.toLowerCase())).then(((response) => {
            if (response.payload.predictions) {
                setLocations(response.payload.predictions);
            } else {
                setLocations([]);
            }
        }))
    }

    const chooseLocation = (placeId) => {
        console.log('choosing location', placeId);
        dispatch(fetchPlaceDetailByID(placeId)).then(((response) => {
            if (response.payload.result.geometry.location) {

                let geometry = response.payload.result.geometry.location;

                let prepareGeometry = {
                    locationLat: geometry.lat,
                    locationLong: geometry.lng
                }
                Cookies.set('location', JSON.stringify(prepareGeometry), { expires: 30 });
                props.onCloseMapButton(false);
            }
        }))
    }

    const chooseStore = (store) => {
        Cookies.set('store', JSON.stringify(store), { expires: 30 });
        if (store && store.verified === "Y") {
            navigate('/home');
        } else {
            navigate('/enternumber');
        }
    }

    const chooseCurrentLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {

                let prepareGeometry = {
                    locationLat: position.coords.latitude,
                    locationLong: position.coords.longitude
                }

                dispatch(loginWithGuest(prepareGeometry)).then((async (response) => {
                    if (response.payload && response.payload.token) {

                        Cookies.set('E_COMM_TOKEN', response.payload.token, { expires: 30 });
                        Cookies.set('authenticated', true, { expires: 30 });
                        Cookies.set('guest', true, { expires: 30 });
                        Cookies.set('location', JSON.stringify(prepareGeometry), { expires: 30 });

                        const searchParams = new URLSearchParams(window.location.search);

                        if (searchParams.get('code')) {
                            await dispatch(fetchStoreByKeyCode(searchParams.get('code'))).then((response) => {
                                chooseStore(response.payload)
                            })
                        } else {
                            getStores(JSON.stringify(prepareGeometry));
                        }
                        setShowMap(false);
                    }
                }))
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    }

    return (
        <>

            <Offcanvas className='home-offcan offcanvas_top' placement='top' show={props.showMap} onHide={props.onCloseMapButton}
                style={{ height: 'fit-content' }}>
                <Offcanvas.Header>
                    <Row className='g-0 w-100'>
                        <Col xs={12} className='d-flex flex-wrap align-items-center ' key={1}>
                            <Offcanvas.Title className='mb-0 pe-4 fs-6'>Select your Location</Offcanvas.Title>
                            <Button size='sm' className='ms-auto bg-transparent border-0 shadow-none text-dark' onClick={props.onCloseMapButton}>
                                <i class="fas fa-close"></i>
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Header>
                <Offcanvas.Body className='pt-2'>
                    <Row className='g-0'>
                        {/* <Col xs={12} className='d-flex flex-wrap align-items-center '>
                            <Offcanvas.Title className='fs-14 fw-bold me-auto'>Select your Location</Offcanvas.Title>
                            <Button className='bg-transparent border-0 shadow-none text-dark' onClick={props.onCloseMapButton}>
                                <i class="fas fa-close"></i>
                            </Button>
                        </Col> */}
                        <Col xs={12} className='mb-2'>
                            <div className="searchBox ">
                                <Button size='sm' className='search-icon'>
                                    <i class="fas fa-search"></i>
                                </Button>
                                <Form.Control className='form-input pe-3 shadow-sm' type='search' placeholder="search delivery location" aria-label="search" aria-describedby="basic-addon1" onChange={onChangeLocation} />
                            </div>
                        </Col>
                        <Col xs={12} className='mb-3'>
                            <Button variant='primary' className='light-btn text-green bg-transparent shadow-none'>
                                <i class="fas fa-location me-1 fs-6"></i>Use Current Location
                            </Button>
                            <hr className='m-0 mt-2' />
                        </Col>
                        <Col xs={12}>
                            <Card className={`cardItem ${locations.length === 0 ? "d-none" : "d-block"}`}>
                                <Card.Body className='px-0 py-2'>
                                    <ListGroup className={locations.length === 0 ? "" : "map_list_group"}>
                                        {locations ?
                                            locations.map((loc, index) => {
                                                return (
                                                    <ListGroup.Item action key={index} className='' onClick={() => chooseLocation(loc.place_id)}>
                                                        <Row className='g-0 fs-14'>
                                                            <Col xs={12} sm={12} className='d-flex align-items-center'>
                                                                <span className='me-3 '>
                                                                    <i class="fas fa-location-dot fs-6 text-blue"></i>
                                                                </span>
                                                                {/* <Image width={50} className='rounded me-3' src={nIcon} /> */}
                                                                <Row className='g-0 w-100'>
                                                                    <Col xs={12}>
                                                                        {loc.description}
                                                                    </Col>
                                                                    <Col xs={12} className='fs-12'>
                                                                        {loc.description}
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )
                                            })
                                            : null
                                        }
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Offcanvas.Body >
            </Offcanvas>
        </>
    )
}



export default (MapModal);
