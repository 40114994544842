import React from 'react';
import { Col, Row, Button, Modal, Image, } from 'react-bootstrap';

function PreviewImageModal(props) {

    return (
        <>
            <Modal show={props.show} centered size='md' className='my_modal'>
                <Modal.Body>
                    <Row className='g-0 fs-14'>
                        <Col xs={12} className='text-end'>
                            <Button className='bg-transparent border-0 shadow-none text-dark' onClick={props.onCloseButton}>
                                <i class="fas fa-close"></i>
                            </Button>
                        </Col>
                        <Col xs={12} className=''>
                            <Image width={300} height={300} className=' d-block mx-auto object-fit-contain' src={props.srcImage} />
                        </Col>
                    </Row >
                </Modal.Body>
            </Modal>
        </>
    )
}



export default (PreviewImageModal);
