import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Form, Image, Placeholder, Row, Spinner } from 'react-bootstrap';
import upiImg from '../../assets/images/upi.png';
import cashOn from '../../assets/images/money.png';
import OrderConfirmedModal from './OrderConfirmedModal';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { createNewOrder, fetchStoreDeliveryMethods, getCustomerAddressList } from '../../slice/dashBoardSlice';
import { useNavigate } from 'react-router-dom';
import DineModal from '../home/DineModal';

function Payment() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [store, setStore] = useState({});
    const [showMessage, setShowMessage] = useState({ open: false, message: '', messageText: '', msgDescription: '', buttonText: '', invoiceNumber: '' });
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) ||{});
    const [addressList, setAddressList] = useState([]);
    const [cookieStore, setCookieStore] = useState(Cookies.get('store') ? JSON.parse(Cookies.get('store')) : {});
    const [selectedAddress, setSelectedAddress] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deliveryMethods, setDeliveryMethods] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        if (Cookies.get('store')) {
            const store = JSON.parse(Cookies.get('store'));
            if (store) {
                setStore(store || {});
                if (store) {
                    getDeliveryMethodsByStoreId(store?.id);
                }
            }
        } else {
            navigate('/choosebrand');
        }
    }, [cookieStore]);

    const getDeliveryMethodsByStoreId = (storeId) => {
        dispatch(fetchStoreDeliveryMethods(storeId)).then((response) => {
            setDeliveryMethods(response.payload);
        })
    }

    useEffect(() => {
        fetcCustomerAddressList();
    }, []);

    const fetcCustomerAddressList = () => {
        try {
            dispatch(getCustomerAddressList()).then((response) => {
                setAddressList(response.payload);

                if (response.payload) {
                    let findAddress = Array.isArray(response.payload) && response.payload.find((add) => add.id === cart.deliveryAddressId);
                    setSelectedAddress(findAddress);
                    setTimeout(() => {
                        setDataLoaded(true);
                    }, 2000);
                } else {
                    setTimeout(() => {
                        setDataLoaded(true);
                    }, 2000);
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    const onCloseButton = () => {
        setShowMessage({ open: false, message: "", messageText: '', msgDescription: '', buttonText: '', invoiceNumber: '' });
        localStorage.removeItem('cart');
        navigate('/home');
    };

    const placeOrder = () => {
        setLoading(true);
        let cartClone = JSON.parse(JSON.stringify(cart));
        if (cartClone && cartClone.items && cartClone.items.length > 0) {
            let info = JSON.parse(localStorage.getItem('info'));
            cartClone.customerId = info.accountId;
            cartClone.storeId = JSON.parse(Cookies.get('store')).id;

            cartClone.items.map((item) => {
                delete item.name;
            });

            try {
                dispatch(createNewOrder(JSON.stringify(cartClone))).then((response) => {
                    setLoading(false);
                    if (response.payload && response.payload.id) {
                        let orderCart = cart;
                        orderCart.customerId = cartClone.customerId;
                        orderCart.storeId = cartClone.storeId;
                        orderCart.id = response.payload.id;
                        localStorage.setItem('cart', JSON.stringify(orderCart));
                        setShowMessage({ open: true, message: "SUCCESS", messageText: "Order Confirmed", msgDescription: "Thank you for your order. You will receive email confirmation shortly.", buttonText: "Continue Shopping", invoiceNumber: response.payload.tempInvoiceNo });
                    } else {
                        setShowMessage({ open: true, message: "FAILED", messageText: "Order Failed", msgDescription: "Please try again", buttonText: "Try Again" });
                    }
                })
            } catch (err) {
                // console.log(err);
            }

        } else {
            alert("Cart is empty...");
        }
    }

    const onCloseModalBtn = () => {
        setShowModal(false);
    }

    const switchOrderType = (orderType) => {
        setShowModal(false);
        if (cart.deliveryMethod === orderType) {
        } else {
            localStorage.setItem('cart', JSON.stringify({ ...cart, deliveryMethod: orderType }));
            setCart({ ...cart, deliveryMethod: orderType })
            navigate('/cart');
        }
    }

    return (
        <>
            <Row className='g-0 justify-content-center payment'>
                <Col xs={12} md={12} lg={6}>
                    <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>
                            <Button size='sm' className='bg-transparent text-dark border-0' onClick={() => cart?.deliveryMethod == "DELIVERY" ? navigate('/checkout') : navigate('/cart')}>
                                <i class="fas fa-arrow-left"></i>
                            </Button>
                            <h5 className='mb-0 w-100 text-center pe-4 fs-6'>Bill Total: <span className='text-blue fs-14'><i class="fas fa-indian-rupee-sign"></i></span>{cart.orderTotal}</h5>
                        </Col>
                    </Row>
                    <Row className='g-0 p-3 pt-2 card-height d-block'>
                        {dataLoaded === true ?
                            <>
                                <Col xs={12} md={12} className=''>
                                    <h5 className='fs-14 fw-bold'>Order Type</h5>
                                </Col>
                                <Col xs={12} md={12} >
                                    {/* <Button variant='primary' className={`light-btn w-100 p-3 ${cart.deliveryMethod === "DELIVERY" ? "mb-2" : ""}`} onClick={() => setShowModal(true)}> */}
                                    <Button variant='primary' className={`light-btn w-100 p-3`} onClick={() => setShowModal(true)}>
                                        <h3 className='fs-14 mb-0'> <i class="fas fa-edit"></i> {cart.deliveryMethod}</h3>
                                    </Button>
                                    {/* <Card className='cardItem mb-2' onClick={() => setShowModal(true)}>
                                <Card.Body className='pb-0'>
                                    <Row className='g-0 fs-14'>
                                        <Col md={12} xs={12} className="mb-2">
                                            {cart.deliveryMethod}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card> */}
                                </Col>
                            </> :
                            <Placeholder animation="glow" as='row' className='g-0'>
                                <Placeholder xs={12} className='mb-2' style={{ width: '25%' }} />
                                <Placeholder xs={12} className='rounded' style={{ height: '50px' }} />
                            </Placeholder>}

                        {cart.deliveryMethod === "DELIVERY" ?
                            <>
                                {dataLoaded === true ?
                                    <>
                                        <Col xs={12} md={12} className='mt-3'>
                                            <h5 className='fs-14 fw-bold'>Location</h5>
                                        </Col>
                                        <Col xs={12}>
                                            <Card className='cardItem'>
                                                <Card.Body className='p-2'>
                                                    <div className="timeline-with-icons">
                                                        <ul className="timeline-item">
                                                            <li>
                                                                <span className="timeline-icon"></span>
                                                                <a href='#'>
                                                                    <span className='d-flex align-items-center'>
                                                                        <h4 className="fs-14 mb-0">{store && store.name ? store.name : ""}</h4>
                                                                        <div className='divider'></div>
                                                                    </span>
                                                                    {/* <p className="fs-12 mb-0 text-blue">Delivery in 25 mins</p> */}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <span className="timeline-icon"></span>
                                                                <a href='#'>
                                                                    <span className='d-flex align-items-center'>
                                                                        <h4 className="fs-14 mb-0">Home</h4>
                                                                        <div className='divider'></div>
                                                                    </span>
                                                                    <p className="fs-12 mb-0 text-blue">{selectedAddress ? selectedAddress.line1 + ' ' + selectedAddress.line2 : null}</p>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </> :
                                    <Placeholder animation="glow" as='row' className='g-0'>
                                        <Placeholder xs={12} className='mb-2 mt-3' style={{ width: '25%' }} />
                                        <Placeholder xs={12} className='rounded' style={{ height: '85px' }} />
                                    </Placeholder>}
                            </> : null}

                        {dataLoaded === true ?
                            <>
                                <Col xs={12} md={12} className='mt-3'>
                                    <h5 className='fs-14 fw-bold'>Payment</h5>
                                </Col>
                                <Col xs={12}>
                                    <Card className='cardItem mb-2' for="cashOnDelivery" as='label' role='button'>
                                        <Card.Body className='p-2'>
                                            <Row className='g-0'>
                                                <Col xs={12} className='d-flex align-items-start position-relative' >
                                                    <Image width='120px' className='border rounded me-3' src={cashOn} />
                                                    <div className='align-self-center me-auto'>
                                                        <span>
                                                            <h3 className='fs-14 mb-1'>Cash On Delivery</h3>
                                                            <p className='mb-0 text-blue fs-12'>This payment method is available</p>
                                                        </span>
                                                    </div>
                                                    <Form.Check type='radio' className='radio-right' id="cashOnDelivery" name="cashOnDelivery" value="cashOnDelivery" checked />
                                                    {/* <Button size='sm' className='arrow-right'>
                                                <i class="fas fa-chevron-right"></i>
                                            </Button> */}
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12}>
                                    <Accordion>
                                        <Accordion.Item eventKey="0" className='cardItem mb-2 disable_arrow '>
                                            <Accordion.Header className='p-0 w-100' for="upiId" as='label'>
                                                <Row className='g-0 w-100'>
                                                    <Col xs={12} className='d-flex align-items-start' >
                                                        <Image width='120px' className='border rounded me-3' src={upiImg} />
                                                        <div className='align-self-center me-auto'>
                                                            <span>
                                                                <h3 className='fs-14 mb-1'>Add New UPI ID</h3>
                                                                <p className='mb-0 text-red fs-12'>This payment method is not available</p>
                                                            </span>
                                                        </div>
                                                        <Form.Check type='radio' className='radio-right' id="upiId" name="upiId" value="upiId" disabled />
                                                    </Col>
                                                </Row>
                                            </Accordion.Header>
                                            {/* <Accordion.Body className='p-2'>
                                        <Row className='g-0 d-flex align-items-center'>
                                            <Col xs={12} className=''>
                                                <FloatingLabel className="mb-2" label="Enter Your UPI ID" controlId="floatingTextarea" >
                                                    <Form.Control className='shadow-box' type="text" placeholder="EnterYourUPIID" />
                                                </FloatingLabel>
                                            </Col>
                                            <Col xs={12} className=''>
                                                <p className='fs-12 text-truncate text-blue'>Your UPI ID will be encrypted and is 100% safe with us</p>
                                            </Col>
                                            <Col xs={12} className=''>
                                                <Button className='w-100 py-3 bg-light-red text-truncate rounded'>
                                                    Save UPI ID
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Accordion.Body> */}
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </> :
                            <Placeholder animation="glow" as='row' className='g-0'>
                                <Placeholder xs={12} className='mb-2 mt-3' style={{ width: '25%' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '100px' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '100px' }} />
                            </Placeholder>}
                    </Row>
                    <Row className='g-0 position-relative'>
                        <Col xs={12} lg={6} className='px-3 btn-position'>
                            {dataLoaded === true ?
                                <>
                                    {loading ?
                                        <Button className='w-100 foot-btn  py-3 bg-light-blue text-truncate' disabled>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </Button> :
                                        <Button className='w-100 py-3 foot-btn bg-light-blue text-truncate' onClick={() => placeOrder()} >
                                            Confirm Order
                                        </Button>
                                    }
                                </> :
                                <Placeholder animation="glow" as='row' className='g-0'>
                                    <Placeholder.Button xs={12} className='rounded' style={{ height: '56px' }} />
                                </Placeholder>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <OrderConfirmedModal show={showMessage} onCloseButton={onCloseButton} />

            <DineModal showModal={showModal} onCloseModalBtn={onCloseModalBtn} switchOrderType={switchOrderType} deliveryMethods={deliveryMethods} />
        </>
    )
}

export default (Payment);
