import React from 'react';
import { Col, Row, Button, Modal, } from 'react-bootstrap';

function ConfirmationModal(props) {

    return (
        <>
            <Modal show={props.show} centered size='md' onHide={props.hideModal}>
                <Modal.Body>
                    <Row className='g-0 fs-14'>
                        <Col xs={12}>
                            <h5 className='text-center fs-14 fw-bold'>
                                {props.messageText}
                            </h5>
                        </Col>
                        <Col xs={6}>
                            <Button className='w-100 py-3 bg-light-blue text-truncate rounded' onClick={() => props.handleClick('YES')}>
                                Yes
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button className='w-100 py-3 bg-light-blue text-truncate rounded' onClick={() => props.handleClick('NO')}>
                                No
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}



export default (ConfirmationModal);
