import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import logo from '../../assets/images/Nlogo.jpg';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import InternetVerifierModal from '../InternetVerifierModal/InternetVerifierModal';
import noImage from '../../assets/images/no-image.png';

const WelcomeScreen = () => {

    let navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        handlePageARoute(navigator.onLine);
    }, []);

    const checkForInterConnection = () => {
        setShowModal(false);
        if (navigator.onLine) {
            return true;
        } else {
            setTimeout(() => {
                setShowModal(true);

            }, 1000);
        }
    }

    const handlePageARoute = (isInternet) => {
        if (isInternet) {
            if (Cookies.get('location')) {
                navigate('/choosebrand');
            }
        } else {
            setShowModal(true);
        }
    }

    const enableCurrentLocation = () => {
        // try {
        //     if ("geolocation" in navigator) {
        //         navigator.geolocation.getCurrentPosition(function (position) {
        let prepareGeometry = {
            locationLat: 30.811100,
            locationLong: 75.170232
        }
        if (prepareGeometry.locationLat && prepareGeometry.locationLong) {
            Cookies.set('location', JSON.stringify(prepareGeometry), { expires: 30 });
            navigate('/choosebrand');
        }
        // })
        // } else {
        //     console.log("Geolocation is not available in your browser.");
        // }
        // } catch (e) {
        //     console.log(e);
        // }
    }

    return (
        <>
            <Row className='g-0 justify-content-center bg-welcScreen'>
                <Col xs={12} md={12} lg={6}>
                    <Row className='g-0 p-3 welcFoodImage'>
                        <Col xs={12} className='d-flex align-items-center mt-4'>
                            <span className='bg-logo'>
                                <Image width='100%' className='logo' src={logo || noImage} />
                            </span>
                        </Col>
                        <Col xs={12} md={12} className='mt-4'>
                            <h5 className='foodFont'>Mehar <br />Collections</h5>
                        </Col>
                    </Row>
                    <Row className='g-0 bottom_card'>
                        <Col xs={12} lg={6} className='bottom_btn'>
                            <Row className='g-0'>
                                <Col xs={12} className='px-3'>
                                    {/* <p className='text-center fs-14'> already have an account ! <a href='/home' className='fs-6'>sign in</a></p> */}
                                </Col>
                                <Col xs={12} className='px-3'>
                                    <Button onClick={() => enableCurrentLocation()} className='w-100 py-3 foot-btn bg-light-blue text-truncate shadow-none' >
                                        Let's Go
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <InternetVerifierModal show={showModal} onCloseButton={() => checkForInterConnection()} />
        </>
    )
}


export default (WelcomeScreen);
