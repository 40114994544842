import React, { useState } from 'react';
import { Button, Card, Col, Form, Image, InputGroup, Row, Spinner } from 'react-bootstrap';
import logo from '../../assets/images/Nlogo.jpg';
import { registerCustomer } from '../../actions/DashBoard/getDashBoardData';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentMobileNumber } from '../../slice/dashBoardSlice';
import Cookies from 'js-cookie';
import noImage from '../../assets/images/no-image.png';

function EnterNumber() {

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = useState('');
    const [loading, setLoading] = useState(false);

    const handleNumberChange = (e) => {
        var format = /[!@#$%^&*`()_+qwertyuioplkjhgfdsazxcvbnmMNBVCXZASDFGHJKLPOIUYTREWQ\-=\[\]{};':"\\|,.<>\/?]+/;

        console.log(format.test(e.target.value))
        if (!format.test(e.target.value)) {
            if (e.target.value.length != 11) {
                setMobileNumber(e.target.value);
                dispatch(setCurrentMobileNumber(e.target.value));
            }
        }
    }

    const getOTP = () => {
        setLoading(true);
        let apiJson = {
            "mobileNumber": mobileNumber,
            "storeId": JSON.parse(Cookies.get('store')).id,
            "locationLat": "30.8159898",
            "locationLong": "75.0537497"
        }

        Cookies.set("mobileNumber", mobileNumber, { expires: 30 });

        dispatch(registerCustomer(apiJson)).then((response) => {
            setLoading(false);
            if (response.payload && response.payload.message && response.payload.message === "SUCCESS") {
                // alert('OTP has been sent successfully to register customer mobile number.');
                navigate('/entercode');
            }
            else if (response.payload && response.payload.error && response.payload.error === "ALREADY_EXIST_ACTIVE_OTP") {
                // alert('OTP already sent to registerd mobile number.');
                navigate('/entercode');
            }
            else {
                alert('Something went wrong, please try again');
            }
        });
    }

    return (
        <>
            <Row className='g-0 justify-content-center position-relative bg-pages enterNumber'>
                <span className=' bg-img1'></span>
                <Col xs={12} md={12} lg={6} style={{ zIndex: '1' }}>
                    <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>
                            <Button size='sm' href='#' className='bg-transparent text-dark border-0 pe-none opacity-0'>
                                <i class="fas fa-arrow-left"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col xs={12} className='d-flex align-items-center justify-content-center mb-4'>
                            <span className='bg-logo'>
                                <Image width='100%' className='logo' src={logo || noImage} />
                            </span>
                        </Col>
                        <Col xs={12} md={12} className='mb-5'>
                            <h5 className='fs-6 fw-bold text-center'>Enter your <br /> mobile number</h5>
                            <p className='text-center fs-14'>You can get a 6 digit code from your store owner</p>
                        </Col>
                    </Row>

                    <Row className='g-0'>
                        <Col xs={12}>
                            <Card className='product-card'>
                                <Card.Body className='pt-4'>
                                    <Row className='g-0 d-flex align-items-center mb-3'>
                                        <Col xs={12} className=''>
                                            <InputGroup className="mb-4 cardItem bg-white">
                                                <InputGroup.Text id="basic-addon1" className="border-0 rounded-start rounded-0 bg-transparent">+91 <span className='borderRight'></span></InputGroup.Text>
                                                <Form.Control
                                                    className='p-3 ps-0 bg-transparent'
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    type='text'
                                                    id="mobileNumber"
                                                    value={mobileNumber}
                                                    onChange={handleNumberChange}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col xs={12} className='text-center'>
                                            {loading ?
                                                <Button className='w-100 foot-btn  py-3 bg-light-blue text-truncate' disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button> :
                                                <>
                                                    {mobileNumber && mobileNumber.length > 9 ?
                                                        <Button className='w-100 foot-btn  py-3 bg-light-blue text-truncate' onClick={getOTP}>
                                                            Send OTP
                                                        </Button>
                                                        :
                                                        <Button className='w-100 foot-btn  py-3 bg-light-blue text-truncate' disabled>
                                                            Send OTP
                                                        </Button>
                                                    }
                                                </>
                                            }
                                        </Col>
                                        {/* <Col xs={12} className='text-center' style={{ marginTop: '10px' }} onClick={() => navigate('/home')}>
                                            <Button className='w-100 foot-btn  py-3 bg-light-blue text-truncate'>
                                                Skip for later
                                            </Button>
                                        </Col> */}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </>
    )
}

export default (EnterNumber);
